import React from 'react';
import { AlertTriangle } from 'lucide-react';

export const Alert = ({ children, variant = 'default', className = '' }) => {
  const variants = {
    default: 'bg-gray-800 border-gray-700 text-gray-200',
    destructive: 'bg-red-900/50 border-red-800 text-red-200',
    success: 'bg-green-900/50 border-green-800 text-green-200'
  };

  return (
    <div className={`p-4 rounded-lg border ${variants[variant]} flex items-center gap-2 ${className}`}>
      <AlertTriangle className="w-5 h-5" />
      {children}
    </div>
  );
};

export const AlertDescription = ({ children, className = '' }) => (
  <p className={`text-sm ${className}`}>{children}</p>
);

export default Alert;