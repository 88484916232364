import React from 'react';
import Navbar from './Navbar';
import ScrollToTop from './ScrollToTop';
import HeroContent from './HeroContent';
import PredictionCards from './PredictionCards';
import PlatformSection from './PlatformSection';
import Features from './Features';
import BettingOverview from './BettingOverview';
import PricingSection from './PricingSection';
import TestimonialsSection from './TestimonialsSection';
import CTASection from './CTASection';
import FAQSection from './FAQSection';
import Footer from './Footer';
import GlobalReferralBox from './GlobalReferralBox';

const Layout = () => {
  return (
    <div className="min-h-screen relative overflow-hidden text-white bg-[#111111]">
      {/* Global background gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-[#111111] to-black opacity-50 pointer-events-none" />
      
      {/* Content */}
      <Navbar />
      
      <main className="min-h-screen flex flex-col pt-24 md:pt-28">
        {/* Hero Section */}
        <section className="flex-1 relative z-10 mb-20 md:mb-32">
          <div className="min-h-[calc(100vh-80px)] flex flex-col md:flex-row items-center justify-center gap-20 max-w-7xl mx-auto px-4 md:px-6">
            <HeroContent />
            <PredictionCards />
          </div>
        </section>

        {/* Content Sections */}
        <div className="w-full max-w-7xl mx-auto px-4 md:px-6 relative z-10 space-y-16 md:space-y-32 overflow-x-hidden">
          <PlatformSection />
          <Features />
          <BettingOverview />
        </div>
      </main>

      {/* Floating action buttons 
      <GlobalReferralBox />*/}
      <div className="fixed bottom-8 right-8 z-[9999]">
        <ScrollToTop />
      </div>

      {/* Bottom Sections */}
      <div className="relative z-10 mt-16 md:mt-32 overflow-x-hidden">
        <PricingSection />
        <TestimonialsSection />
        <CTASection />
        <FAQSection />
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
