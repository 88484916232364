import React from 'react';
import { Check } from 'lucide-react';

const PricingSection = () => {
  return (
    <div className="py-20">
      <div className="container mx-auto px-6 text-center">
        {/* Heading with gradient */}
        <h2 className="text-5xl md:text-6xl font-light mb-6 tracking-tight">
          <div className="inline-block" style={{
            background: 'linear-gradient(90deg, #F7D618 0%, #00F7F7 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: '0 0 20px rgba(0,247,247,0.2)'
          }}>
            Simple pricing,
          </div>
          <br />
          <span className="text-white">Endless Opportunities.</span>
        </h2>

        <p className="text-xl text-white mb-12 max-w-2xl mx-auto">
          We keep our prices low in order to offer exceptional value and flexibility.
        </p>

        <button className="px-8 py-3 rounded-full bg-[#0D0E14] border border-[#2A2D36] text-white hover:bg-[#1A1C24] transition-all duration-300 mb-16">
          Buy Now
        </button>

        <div className="flex flex-col md:flex-row gap-8 max-w-5xl mx-auto">
          {/* Base Package Card */}
          <div className="flex-1 rounded-2xl p-[1px] relative" style={{
            background: 'linear-gradient(135deg, #00F7F7 0%, #F7D618 100%)'
          }}>
            <div className="h-full bg-[#0D0E14] rounded-2xl p-8">
              <div className="text-xl text-white mb-8">OVERDOG BASE PACKAGE</div>
              <div className="flex items-center justify-center gap-4 mb-8">
                <span className="text-[#8A8F98] text-2xl line-through">$34.99</span>
                <span className="text-white text-4xl">$27.99</span>
              </div>
              
              <div className="p-2 bg-[#1A1C24] rounded-lg mb-8">
                <select className="w-full bg-transparent text-white px-4 py-2 outline-none">
                  <option>Select a Plan</option>
                </select>
              </div>

              <div className="space-y-4 text-left">
                <div className="flex items-center gap-3">
                  <Check className="text-[#00F7F7] w-5 h-5" />
                  <span className="text-[#00F7F7]">Access To All AI Predictions</span>
                </div>
                <div className="flex items-center gap-3">
                  <Check className="text-[#8A8F98] w-5 h-5" />
                  <span className="text-[#8A8F98]">Live Scoreboards</span>
                </div>
                <div className="flex items-center gap-3">
                  <Check className="text-[#8A8F98] w-5 h-5" />
                  <span className="text-[#8A8F98]">Sports Betting Courses</span>
                </div>
                <div className="flex items-center gap-3">
                  <Check className="text-[#8A8F98] w-5 h-5" />
                  <span className="text-[#8A8F98]">Automatic Bet Tracking</span>
                </div>
              </div>
            </div>
          </div>

          {/* Add-Ons Card */}
          <div className="flex-1 rounded-2xl p-[1px] relative" style={{
            background: 'linear-gradient(135deg, #00F7F7 0%, #234CAD 100%)'
          }}>
            <div className="h-full bg-[#0D0E14] rounded-2xl p-8">
              <div className="flex justify-between items-center mb-8">
                <div className="text-xl text-white">Add-Ons</div>
                <div className="text-[#00F7F7]">+$14.99/month</div>
              </div>

              <div className="space-y-4 text-left mb-8">
                <div className="flex items-center gap-3">
                  <span className="text-[#00F7F7]">• Arbitrage Tools</span>
                </div>
                <div className="flex items-center gap-3">
                  <span className="text-[#00F7F7]">• Live Predictions</span>
                </div>
                <div className="flex items-center gap-3">
                  <span className="text-[#00F7F7]">• Advanced Bet Tracking Metrics</span>
                </div>
                <div className="flex items-center gap-3">
                  <span className="text-[#00F7F7]">• AutoBet ™</span>
                </div>
                <div className="flex items-center gap-3">
                  <span className="text-[#00F7F7]">• Player Props Predictions</span>
                </div>
              </div>

              <div className="bg-[#1A1C24] rounded-lg p-6 mb-8">
                <p className="text-[#8A8F98] text-sm leading-relaxed">
                  DON'T SETTLE FOR "GOOD ENOUGH." UPGRADING TO THE EXTRA FEATURES IS THE STRATEGIC CHOICE FOR THOSE WHO WANT TO DOMINATE AND ACHIEVE PEAK PERFORMANCE. IT'S A GAME-CHANGER THAT WILL PUT YOU IN THE WINNER'S CIRCLE.
                </p>
              </div>

              <div className="text-center text-[#8A8F98] text-sm">
                Get reimbursed up to $25 if your first OVERDOG bet loses, no questions asked.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
