import React from 'react';
import { LineChart, Line, YAxis } from 'recharts';
import { Bell, TrendingUp } from 'lucide-react';

// Sample data for the ROI chart
const roiData = [
  { value: 100 }, { value: 200 }, { value: 150 }, { value: 400 }, 
  { value: 300 }, { value: 600 }, { value: 500 }, { value: 800 }
];

interface FeatureCardProps {
  title: string;
  description: string;
  children: React.ReactNode;
  gradient?: string;
}

const FeatureCard = ({ title, description, children, gradient }: FeatureCardProps) => (
  <div 
    className="rounded-xl p-6 h-full transition-transform duration-300 hover:scale-[1.02]"
    style={{
      background: gradient || 'linear-gradient(to b, #1A1C24, #0D0E14)',
      border: '1px solid rgba(255,255,255,0.1)'
    }}
  >
    <h3 className="text-xl font-medium text-white mb-2">{title}</h3>
    <p className="text-sm text-[#8A8F98] mb-4">{description}</p>
    {children}
  </div>
);

const Features = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto p-6">
      {/* Expert Certified Picks */}
      <FeatureCard
        title="Expert Certified Picks"
        description="Almost all of our AI picks get vetted by our team of experts."
      >
        <div className="bg-[#1A1C24] rounded-lg p-4">
          <div className="flex items-center justify-between mb-4">
            <span className="text-lg text-white">Dallas Mavericks</span>
            <span className="text-[#00F7A8] text-sm">To Win</span>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-4">
              <img src="/api/placeholder/32/32" alt="Thunder" className="w-8 h-8" />
              <span className="text-[#8A8F98]">Thunder</span>
              <img src="/api/placeholder/32/32" alt="Mavericks" className="w-8 h-8" />
              <span className="text-[#8A8F98]">Mavericks</span>
            </div>
          </div>
          <div className="text-center text-2xl text-white">OVER 207.5</div>
        </div>
      </FeatureCard>

      {/* Free Money */}
      <FeatureCard
        title="Free Money"
        description="Sign up for sportsbooks using our codes to get free cash and offers."
      >
        <div className="space-y-4">
          <div className="bg-purple-900/30 rounded-lg p-3 flex items-center justify-center">
            <span className="text-white text-sm">100% DEPOSIT MATCH UP TO $100</span>
          </div>
          <div className="bg-blue-900/30 rounded-lg p-3 flex items-center justify-center">
            <span className="text-white text-sm">BET $5, GET $200 IF YOU WIN</span>
          </div>
          <div className="bg-emerald-900/30 rounded-lg p-3 flex items-center justify-center">
            <span className="text-white text-sm">$1,000 FIRST BET ON CAESARS</span>
          </div>
        </div>
      </FeatureCard>

      {/* Variety of Bets */}
      <FeatureCard
        title="Variety of Bets"
        description="Moneylines, Total Scores, Player Props, and so much more."
        gradient="linear-gradient(135deg, rgba(253,224,71,0.1), rgba(0,247,247,0.1))"
      >
        <div className="grid grid-cols-2 gap-4">
          <img src="/api/placeholder/160/90" alt="Bet Preview" className="rounded-lg" />
          <img src="/api/placeholder/160/90" alt="Bet Preview" className="rounded-lg" />
          <img src="/api/placeholder/160/90" alt="Bet Preview" className="rounded-lg" />
          <img src="/api/placeholder/160/90" alt="Bet Preview" className="rounded-lg" />
        </div>
      </FeatureCard>

      {/* Track Your Growth */}
      <FeatureCard
        title="Track Your Growth"
        description="Track your bankroll across the bets you place using our platform.... This will help you to make key decisions regarding the use of our platform."
      >
        <div className="h-48 w-full">
          <LineChart data={roiData} width={300} height={150}>
            <YAxis hide={true} />
            <Line 
              type="monotone" 
              dataKey="value" 
              stroke="#00F7F7" 
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
          <div className="text-[#00F7A8] text-2xl mt-2">+914% ROI</div>
        </div>
      </FeatureCard>

      {/* Sportsbook Arbitrage */}
      <FeatureCard
        title="Sportsbook Arbitrage"
        description="Capitalize on pricing errors and odds differences across sportsbooks."
        gradient="linear-gradient(135deg, rgba(253,224,71,0.1), rgba(0,247,247,0.1))"
      >
        <div className="bg-[#1A1C24] rounded-lg p-4 space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-[#8A8F98]">ODDS</span>
            <span className="text-[#8A8F98]">OUR PICK/STAKE</span>
          </div>
          <div className="bg-[#2A2D36] rounded p-2 flex justify-between">
            <span>STAKE</span>
            <span>STAKE +1.50</span>
          </div>
          <div className="bg-[#234CAD] rounded p-2 flex justify-between">
            <span>FLIFF</span>
            <span>+125</span>
          </div>
          <div className="bg-[#1A75FF] rounded p-2 flex justify-between">
            <span>FANDUEL</span>
            <span>+100</span>
          </div>
        </div>
      </FeatureCard>

      {/* Community */}
      <FeatureCard
        title="Community"
        description="Chat, post your wins and losses, and connect with other bettors so we can win as a community!"
        gradient="linear-gradient(135deg, rgba(253,224,71,0.1), rgba(0,247,247,0.1))"
      >
        <div className="bg-[#1A1C24] rounded-lg p-4">
          <div className="flex items-center space-x-4 mb-4">
            <img src="/api/placeholder/40/40" alt="User" className="rounded-full" />
            <div>
              <h4 className="text-white font-medium">HIT THIS 5 LEG LAY!</h4>
              <p className="text-sm text-[#8A8F98]">User used AI Picks</p>
            </div>
          </div>
          <div className="text-[#8A8F98] text-sm">
            <div>Sportsbook: Fliff</div>
            <div>Total Risk: $250.00</div>
            <div>Total Win: $1,185.00</div>
          </div>
        </div>
      </FeatureCard>

      {/* Data Exploration */}
      <FeatureCard
        title="Data Exploration"
        description="Explore all kinds of data across our platform, such as player performance, team performance, current standings, and so much more."
      >
        <div className="bg-[#1A1C24] rounded-lg p-4">
          <img src="/api/placeholder/300/150" alt="Data Chart" className="w-full rounded-lg" />
        </div>
      </FeatureCard>

      {/* Alerts */}
      <FeatureCard
        title="Alerts"
        description="Get instant reminders for changed odds, new AI predictions, wins and losses."
      >
        <div className="space-y-4">
          <div className="bg-[#1A1C24] border border-[#00F7F7]/20 rounded-lg p-3 flex items-center space-x-2">
            <Bell className="text-[#00F7F7] w-4 h-4" />
            <span className="text-white text-sm">Odds Changed for your Lakers Moneyline bet!</span>
          </div>
          <div className="bg-[#1A1C24] border border-[#F7D618]/20 rounded-lg p-3 flex items-center space-x-2">
            <Bell className="text-[#F7D618] w-4 h-4" />
            <span className="text-white text-sm">New Prediction for Luka Doncic Points (Over 33.5)</span>
          </div>
          <div className="bg-[#1A1C24] border border-red-500/20 rounded-lg p-3 flex items-center space-x-2">
            <Bell className="text-red-500 w-4 h-4" />
            <span className="text-white text-sm">Zion Williamson is out due to an injury (Ankle)</span>
          </div>
        </div>
      </FeatureCard>
    </div>
  );
};

export default Features;
