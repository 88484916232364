// contexts/UserContext.tsx
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase/client';
import { useAuthContext } from './AuthContext';

interface UserProfile {
  id: string;
  email: string | null;
  username: string | null;
  first_name: string | null;
  last_name: string | null;
  country: string | null;
  birthday: string | null;
  phone: string | null;
  plan: string;
  referral: string | null;
  affiliate: any | null;
  bets: string | null;
  customer_id: string | null;
  avatar: string;
  config: any | null;
  discord_user: string | null;
  selected_sportsbooks: {
    [key: string]: {
      selected: boolean;
      selected_at: string;
      autobet_settings?: {  // Add this property
        minOdds: string;
        maxOdds: string;
        stakeAmount: string;
      };
    };
  };
  connected_sportsbooks: {
    [key: string]: {
      connected: boolean;
      connected_at: string;
    };
  };
}

interface UserContextType {
  profile: UserProfile | null;
  loading: boolean;
  error: Error | null;
  updateProfile: (updates: Partial<UserProfile>) => Promise<void>;
  refreshProfile: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: React.ReactNode;
}

const UserProviderComponent: React.FC<UserProviderProps> = ({ children }) => {
  const { user } = useAuthContext();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const mountedRef = useRef(true);

  const fetchUserProfile = async (userId: string) => {
    if (!mountedRef.current) return;

    try {
      const { data, error: fetchError } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();

      if (fetchError) {
        if (fetchError.code === 'PGRST116') { // No rows returned
          const { data: newProfile, error: insertError } = await supabase
            .from('users')
            .insert([
              {
                id: userId,
                email: user?.email,
                username: user?.email?.split('@')[0],
                plan: 'commoner',
                avatar: '6808da5325.png'
              }
            ])
            .select()
            .single();

          if (insertError) throw insertError;
          setProfile(newProfile);
        } else {
          throw fetchError;
        }
      } else {
        setProfile(data);
      }

      setError(null);
    } catch (err) {
      console.error('Error in fetchUserProfile:', err);
      setError(err as Error);
    } finally {
      if (mountedRef.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    
    // Only fetch profile if we have a user AND we're on a protected route
    if (user?.id) {
      fetchUserProfile(user.id);
    } else {
      // If no user, clear profile and loading state
      if (profile !== null) {
        setProfile(null);
      }
      if (loading) {
        setLoading(false);
      }
    }

    return () => {
      mountedRef.current = false;
    };
  }, [user?.id]); // Removed fetchUserProfile from dependencies

  const updateProfile = async (updates: Partial<UserProfile>) => {
    if (!user?.id) throw new Error('No user found');
    
    try {
      const { data, error } = await supabase
        .from('users')
        .update(updates)
        .eq('id', user.id)
        .select()
        .single();

      if (error) throw error;
      setProfile(data);
    } catch (err) {
      console.error('Error updating profile:', err);
      throw err;
    }
  };

  const refreshProfile = async () => {
    if (user?.id) {
      await fetchUserProfile(user.id);
    }
  };

  return (
    <UserContext.Provider value={{ profile, loading, error, updateProfile, refreshProfile }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = React.memo(UserProviderComponent);

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
