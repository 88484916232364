import { useEffect, useState, useMemo } from 'react';
import { NBAPrediction, NBAPredictionsResponse } from '../../../types/nbaPredictions';
import { fetchNBAPredictions } from '../../../services/nbaPredictionsService';
import { useAuth } from '../../../hooks/useAuth';
import { supabase } from '../../../lib/supabase/client';
import BettingModal from './BettingModal';
import NBAGameModal from './NBAGameModal';
import { useOddsLocation } from '@/contexts/OddsLocationContext';

export interface NBASinglesProps {}

const teamAbbreviations: { [key: string]: string } = {
  'ATL': 'hawks',
  'BOS': 'celtics',
  'BKN': 'nets',
  'CHA': 'hornets',
  'CHI': 'bulls',
  'CLE': 'cavaliers',
  'DAL': 'mavericks',
  'DEN': 'nuggets',
  'DET': 'pistons',
  'GSW': 'warriors',
  'HOU': 'rockets',
  'IND': 'pacers',
  'LAC': 'clippers',
  'LAL': 'lakers',
  'MEM': 'grizzlies',
  'MIA': 'heat',
  'MIL': 'bucks',
  'MIN': 'timberwolves',
  'NOP': 'pelicans',
  'NYK': 'knicks',
  'OKC': 'thunder',
  'ORL': 'magic',
  'PHI': '76ers',
  'PHX': 'suns',
  'POR': 'trailblazers',
  'SAC': 'kings',
  'SAS': 'spurs',
  'TOR': 'raptors',
  'UTA': 'jazz',
  'WAS': 'wizards'
};

export function getTeamName(teamName: string): string {
  // Get name from abbreviations or convert to lowercase
  let name = teamAbbreviations[teamName] || teamName.toLowerCase();
  
  // Remove spaces
  name = name.replace(/\s+/g, '');
  
  // Remove common city prefixes
  const cityPrefixes = [
    'portland', 'losangeles', 'newyork', 'goldenstate', 
    'sanantonio', 'houston', 'oklahomacity', 'dallas', 
    'denver', 'sacramento', 'utah', 'miami', 'cleveland',
    'boston', 'memphis', 'indiana', 'orlando', 'toronto',
    'brooklyn', 'neworleans', 'philadelphia', 'chicago',
    'atlanta', 'milwaukee', 'phoenix', 'washington'
  ];
  
  for (const prefix of cityPrefixes) {
    if (name.startsWith(prefix)) {
      return name.slice(prefix.length);
    }
  }
  
  return name;
};

export const NBASingles = ({}: NBASinglesProps) => {
  const [predictions, setPredictions] = useState<NBAPredictionsResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedGame, setSelectedGame] = useState<NBAPrediction | null>(null);
  const [isBettingModalOpen, setIsBettingModalOpen] = useState(false);
  const [isGameModalOpen, setIsGameModalOpen] = useState(false);
  const { user } = useAuth();
  const { oddsFormat } = useOddsLocation();

  useEffect(() => {
    const loadPredictions = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.access_token) {
          const data = await fetchNBAPredictions(session.access_token);
          setPredictions(data);
        }
      } catch (err) {
        setError('Failed to load predictions');
        console.error(err);
      }
    };

    if (user) {
      loadPredictions();
    }
  }, [user]);

  const handleAddBet = (game: NBAPrediction, e: React.MouseEvent) => {
    e.stopPropagation();
    if (game.prediction.winner !== "AI Unsure") {
      setSelectedGame(game);
      setIsBettingModalOpen(true);
    }
  };

  const handleGameClick = (game: NBAPrediction) => {
    console.log('Game data being passed to modal:', game);
    setSelectedGame(game);
    setIsGameModalOpen(true);
  };

  if (error) {
    return (
      <div className="text-red-500 p-4">
        {error}
      </div>
    );
  }

  if (!predictions) {
    return (
      <div className="text-white p-4">
        Loading predictions...
      </div>
    );
  }

  return (
    <>
      <div className="space-y-4 w-full px-4">
        {predictions.games
          .slice() // Create a copy to avoid mutating original array
          .sort((a, b) => {
            const aProb = a.prediction.winner === a.teams.home.name
              ? a.prediction.home_win_prob
              : a.prediction.away_win_prob;
            const bProb = b.prediction.winner === b.teams.home.name
              ? b.prediction.home_win_prob
              : b.prediction.away_win_prob;
            return (bProb ?? 0) - (aProb ?? 0); // Sort descending
          })
          .map((game, index) => (
          <div
            key={index}
            className="bg-[#13131A] rounded-xl overflow-hidden transition-colors w-full border border-[#2E2E3A] cursor-pointer"
            onClick={() => handleGameClick(game)}
          >
            <div className="grid grid-cols-[auto_1fr_auto] gap-6 p-4 w-full items-center bg-[#1A1A24] rounded-lg">
              {/* Logos Section */}
              <div className="flex items-center gap-4 ml-8">
                <img
                  src={`/assets/img/nba-logos/${getTeamName(game.teams.home.name)}.png`}
                  alt={game.teams.home.name}
                  className="w-12 h-12"
                />
                <div className="text-[#8F9BB3] text-sm">vs</div>
                <img
                  src={`/assets/img/nba-logos/${getTeamName(game.teams.away.name)}.png`}
                  alt={game.teams.away.name}
                  className="w-12 h-12"
                />
              </div>

              {/* Stats Section */}
              <div className="flex gap-8 items-center mx-auto">
                <div className="flex flex-col items-center">
                  <img
                    src={`/assets/img/nba-logos/${getTeamName(game.teams.home.name)}.png`}
                    alt={game.teams.home.name}
                    className="w-8 h-8 mb-1"
                  />
                  <div className="text-[#8F9BB3] text-xs mb-1">Home</div>
                  <div className="text-white text-sm">
                    {game.recent_form?.home || 'N/A'}
                  </div>
                  <div className="text-white text-sm">
                    {game.team_health && typeof game.team_health.home === 'number' ? game.team_health.home.toFixed(1) + '%' : 'N/A'}
                  </div>
                </div>
                
                <div className="flex flex-col items-center">
                  <img
                    src={`/assets/img/nba-logos/${getTeamName(game.teams.away.name)}.png`}
                    alt={game.teams.away.name}
                    className="w-8 h-8 mb-1"
                  />
                  <div className="text-[#8F9BB3] text-xs mb-1">Away</div>
                  <div className="text-white text-sm">
                    {game.recent_form?.away || 'N/A'}
                  </div>
                  <div className="text-white text-sm">
                    {game.team_health && typeof game.team_health.away === 'number' ? game.team_health.away.toFixed(1) + '%' : 'N/A'}
                  </div>
                </div>
              </div>

              {/* Prediction & Add Bet Section */}
              <div className="flex flex-col items-end gap-2">
                <div>
                  <div className="text-[#8F9BB3] text-xs mb-1">Prediction</div>
                  <div className="text-white text-sm font-semibold text-right">
                    {game.prediction.winner}
                  </div>
                  <div className="w-16 text-right">
                    <div className={`text-sm ${
                      (game.prediction.winner === game.teams.home.name
                        ? (game.prediction.home_win_prob ?? 0)
                        : (game.prediction.away_win_prob ?? 0)) >= 60
                        ? 'text-green-400'
                        : 'text-[#00F6FF]'
                    }`}>
                      {Number(
                        game.prediction?.winner === game.teams.home.name
                          ? game.prediction?.home_win_prob ?? 0
                          : game.prediction?.away_win_prob ?? 0
                      ).toFixed(1)}%
                    </div>
                  </div>
                </div>
                <button
                  onClick={(e) => handleAddBet(game, e)}
                  className="bg-[#4263EB] text-white py-1 px-3 rounded-md text-sm font-medium hover:bg-[#3651C9] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={game.prediction.winner === "AI Unsure"}
                >
                  Add Bet
                </button>
              </div>

            </div>
          </div>
        ))}
      </div>

      {selectedGame && (
        <>
          <BettingModal
            isOpen={isBettingModalOpen}
            onClose={() => {
              setIsBettingModalOpen(false);
              setSelectedGame(null);
            }}
            game={selectedGame}
            eventId={selectedGame?.eventid}
            oddsFormat={oddsFormat}
          />

          <NBAGameModal
            isOpen={isGameModalOpen}
            onClose={() => {
              setIsGameModalOpen(false);
              setSelectedGame(null);
            }}
            game={{
              ...selectedGame,
              teams: {
                ...selectedGame.teams,
                away: {
                  ...selectedGame.teams.away,
                  name: getTeamName(selectedGame.teams.away.name)
                },
                home: {
                  ...selectedGame.teams.home,
                  name: getTeamName(selectedGame.teams.home.name)
                }
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default NBASingles;
