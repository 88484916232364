import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { AuthProvider, useAuthContext } from './contexts/AuthContext';
import { UserProvider } from './contexts/UserContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { ToastProvider } from './contexts/ToastContext';
import { supabase } from './lib/supabase/client';
import { AppRoutes } from './routes/protected/AppRoutes';
import { ErrorBoundary } from './components/ErrorBoundary';
import { OddsLocationProvider } from './contexts/OddsLocationContext';
import Layout from './components/landing/Layout';

const AppContent = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />} />
      <Route path="/*" element={<AppRoutes />} />
    </Routes>
  );
};

function App() {
  return (
    <OddsLocationProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <AuthProvider supabase={supabase}>
            <UserProvider>
              <ToastProvider>
                <NotificationProvider>
                  <AppContent />
                </NotificationProvider>
              </ToastProvider>
            </UserProvider>
          </AuthProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </OddsLocationProvider>
  );
}

export default App;
