import React from 'react';

export const bankrollLessons = [
  {
    title: "Bankroll Management",
    content: `Effective bankroll management is one of the most crucial aspects of successful sports betting. By controlling how much you wager and ensuring disciplined betting, you can protect your funds and increase your chances of long-term success. Below are detailed lessons on managing your bankroll effectively.`
  },
  {
    title: "Lesson 1: Understanding Your Bankroll",
    content: `What is a Bankroll?
Your bankroll is the total amount of money you have set aside exclusively for betting. It's important to separate this from your everyday finances.

Why is Bankroll Management Important?
- Prevents overspending and protects you from significant losses
- Helps you stay disciplined and consistent, especially during winning or losing streaks
- Enables you to assess your performance objectively over time

Setting Your Bankroll:
Decide how much you can afford to lose without impacting your financial stability. This should be an amount you're comfortable risking.`
  },
  {
    title: "Lesson 2: Bet Sizing Strategies",
    content: `Flat Betting:
Wager a consistent percentage (usually 1-3%) of your bankroll on every bet, regardless of confidence level or odds.

Example: If your bankroll is $1,000 and you bet 2% per wager, each bet would be $20.
Advantages: Simple and minimizes the risk of significant losses.

Unit-Based Betting:
Divide your bankroll into "units" (e.g., 1% of your bankroll equals one unit). Bet 1-5 units depending on your confidence in the wager.

Example: A $1,000 bankroll with $10 units means a high-confidence bet might be 5 units ($50).

Kelly Criterion:
A mathematical formula used to calculate the optimal bet size based on your edge and odds:
Bet Size = Bankroll × (Edge / Odds)

Example: If you estimate a 60% chance of winning a bet with odds of 2.00, the formula suggests betting 20% of your bankroll.
Caution: This method can result in large bet sizes, so many bettors use a "fractional Kelly" approach (e.g., 50% of the calculated amount).

Proportional Betting:
Adjust your bet size based on your bankroll's current value. If your bankroll grows, increase bet sizes; if it shrinks, decrease them.`
  },
  {
    title: "Lesson 3: Risk Management",
    content: `Set a Loss Limit:
Decide on a maximum amount you're willing to lose in a single day, week, or month. Stop betting once you hit this limit.

Avoid Chasing Losses:
It's tempting to increase bet sizes after a loss to recover quickly, but this can lead to larger losses. Stick to your strategy.

Diversify Bets:
Don't put all your bankroll on a single event. Spread your wagers across multiple bets to reduce risk.

Bankroll Growth Goal:
Set realistic goals for bankroll growth. For example, aim to increase your bankroll by 10% per month rather than trying to double it quickly.`
  },
  {
    title: "Lesson 4: Tracking Your Bankroll",
    content: `Keep Detailed Records:
Track all bets, including the amount wagered, type of bet, odds, result, and profit/loss. This helps you evaluate your performance and identify areas for improvement.

Analyze Trends:
Look for patterns in your betting habits. For instance, are you more successful with certain bet types or sports? Use this information to refine your strategy.

Reassess Periodically:
Review your bankroll and betting strategy regularly to ensure they align with your goals and performance.`
  },
  {
    title: "Lesson 5: Adapting to Winning and Losing Streaks",
    content: `Winning Streaks:
- Don't increase bet sizes drastically due to overconfidence
- Stick to your strategy and reinvest profits cautiously
- Consider setting aside a portion of winnings as a "reserve bankroll"

Losing Streaks:
- Avoid emotional decisions or "doubling down" to recover losses
- Reduce bet sizes if your bankroll shrinks to ensure you can continue betting long-term`
  },
  {
    title: "Lesson 6: Emotional Discipline",
    content: `Separate Betting from Emotions:
Don't let excitement or frustration influence your decisions. Every bet should be based on data and analysis, not impulses.

Stay Objective:
Treat betting as a long-term investment, not a way to make quick money. Focus on consistency over time rather than immediate results.`
  },
  {
    title: "Lesson 7: Tools and Resources for Bankroll Management",
    content: `Bankroll Management Software:
Use apps or spreadsheets to track your bankroll, calculate percentages, and monitor performance.

Betting Calculators:
Online tools can help determine optimal bet sizes for different scenarios, such as Kelly Criterion calculators.

Odds Trackers:
Compare odds from multiple bookmakers to ensure you're getting the best value for your bets.`
  },
  {
    title: "Lesson 8: Building a Sustainable Strategy",
    content: `Set Long-Term Goals:
Establish clear objectives, such as achieving a specific bankroll size within a set timeframe or maintaining a consistent ROI.

Focus on Value:
Only place bets where you believe the odds offer value compared to the actual probability of an event occurring.

Evolve with Experience:
As you gain experience, refine your approach to bankroll management, incorporating strategies that work best for you.`
  }
];