import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// Sample data for the chart
const chartData = [
  { date: 'Jan', value: 0 },
  { date: 'Feb', value: -100 },
  { date: 'Mar', value: -200 },
  { date: 'Apr', value: -300 },
  { date: 'May', value: -400 },
  { date: 'Jun', value: -500 },
  { date: 'Jul', value: 100 },
];

interface StatBoxProps {
  label: string;
  value: string;
  textColor?: string;
}

const StatBox = ({ label, value, textColor = 'text-[#00F7F7]' }: StatBoxProps) => (
  <div className="bg-[#1A1C24] rounded-lg p-4">
    <div className="text-[#8A8F98] text-sm mb-2">{label}</div>
    <div className={`text-xl ${textColor} font-medium`}>{value}</div>
  </div>
);

const BettingOverview = () => {
  return (
    <div className="py-20">
      <div className="container mx-auto px-6">
        {/* Heading with gradient */}
        <h2 className="text-5xl md:text-6xl font-light mb-2 tracking-tight">
          <span className="text-white">Get to know your bets,</span>
          <br />
          <span className="inline-block" style={{
            background: 'linear-gradient(90deg, #F7D618 0%, #00F7F7 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: '0 0 20px rgba(0,247,247,0.2)'
          }}>
            Improve
          </span>
          <span className="text-white"> them instantly.</span>
        </h2>

        {/* Overview Panel */}
        <div className="mt-12 bg-[#0D0E14] rounded-2xl border border-[#1A1C24] p-6">
          <div className="text-xl text-white mb-2">Betting Overview</div>
          <div className="text-[#8A8F98] text-sm mb-8">
            A birds eye view of your entire betting behaviour, history, and achievements. (Sourced from a real user using the Fliff Sportsbook)
          </div>

          {/* Chart Section */}
          <div className="bg-[#1A1C24] rounded-xl p-6 mb-8">
            <div className="text-xl text-white mb-4">OVERDOG AI INSIGHTS</div>
            <div className="h-[300px] w-full">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={chartData}>
                  <XAxis 
                    dataKey="date" 
                    stroke="#8A8F98" 
                    fontSize={12}
                  />
                  <YAxis 
                    stroke="#8A8F98" 
                    fontSize={12}
                  />
                  <Tooltip 
                    contentStyle={{ 
                      background: '#1A1C24',
                      border: '1px solid #2A2D36',
                      borderRadius: '8px'
                    }}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="value" 
                    stroke="#00F7A8"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <StatBox label="TOTAL BETS" value="2792" />
            <StatBox label="WINNING BETS" value="435" />
            <StatBox label="LOSING BETS" value="2357" />
            <StatBox label="WIN RATE" value="15.58%" />
            <StatBox label="TOTAL RISKED" value="$28743.96" />
            <StatBox label="NET PROFIT/LOSS" value="$1706.40" />
            <StatBox label="AVERAGE BET SIZE" value="$10.30" />
            <StatBox label="ROI" value="5.94%" />
            <StatBox label="AVERAGE LOSS" value="$12.20" />
            <StatBox label="LARGEST WIN" value="$4997.50" />
            <StatBox label="LARGEST LOSS" value="$120.00" />
            <StatBox label="MEDIAN BET SIZE" value="$10.00" />
          </div>

          {/* AI Plan Section */}
          <div className="mt-8 p-4 bg-[#1A1C24] rounded-lg flex items-center justify-between">
            <span className="text-white">Our AI Plan</span>
            <button className="text-[#8A8F98] hover:text-white transition-colors">
              <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
              >
                <path d="M6 9l6 6 6-6"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BettingOverview;
