// LineCheck.js

import React, { useState, useEffect } from 'react';
import { ChevronDown, Calculator, TrendingUp, TrendingDown } from 'lucide-react';
import { statCategories, calculateCombinedStats } from './statsConfig'; // Importujemy z nowego pliku

// Result Badge Component
const ResultBadge = ({ result }) => {
  if (!result) return null;
  
  const { hits, total, percentage } = result;
  const isGood = percentage > 60;
  
  return (
    <div className={`
      flex items-center gap-2 px-4 py-2 rounded-lg
      ${isGood ? 'bg-emerald-500/10 text-emerald-400' : 'bg-red-500/10 text-red-400'}
    `}>
      {isGood ? <TrendingUp size={16} /> : <TrendingDown size={16} />}
      <span className="font-medium">
        {hits}/{total} games ({percentage.toFixed(1)}%)
      </span>
    </div>
  );
};

const LineCheck = ({ games, onStatChange, onLineValueChange, initialStat = 'PTS' }) => {
  const [formData, setFormData] = useState({
    statType: initialStat,
    value: '',
    overUnder: 'over'
  });
  const [result, setResult] = useState(null);

  useEffect(() => {
    setFormData(prev => ({ ...prev, statType: initialStat }));
  }, [initialStat]);

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateResult();
  };

  const calculateResult = () => {
    const { statType, value, overUnder } = formData;
    
    // Notify parent about current line check values
    onLineValueChange?.({
      value: value,
      overUnder: overUnder
    });
    
    let hits = 0;

    games.forEach(game => {
      const gameValue = calculateCombinedStats(game, statType); // Używamy funkcji calculateCombinedStats

      if (overUnder === 'over' && gameValue > Number(value)) hits++;
      if (overUnder === 'under' && gameValue < Number(value)) hits++;
    });

    setResult({
      hits,
      total: games.length,
      percentage: (hits / games.length) * 100
    });
  };

  const handleStatTypeChange = (e) => {
    const newStatType = e.target.value;
    setFormData(prev => ({ ...prev, statType: newStatType }));
    onStatChange?.(newStatType);
    // Reset line value when changing stat type
    onLineValueChange?.(null);
    setResult(null);
  };

  const handleValueChange = (e) => {
    const newValue = e.target.value;
    setFormData(prev => ({ ...prev, value: newValue }));
    // Reset result when changing value
    setResult(null);
    onLineValueChange?.(null);
  };

  const handleOverUnderChange = (e) => {
    const newOverUnder = e.target.value;
    setFormData(prev => ({ ...prev, overUnder: newOverUnder }));
    // Reset result when changing over/under
    setResult(null);
    onLineValueChange?.(null);
  };

  return (
    <div className="space-y-6 bg-gray-800/40 rounded-xl border border-gray-700 p-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Stat Type Select */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-400">
              Stat Type
            </label>
            <div className="relative">
              <select
                value={formData.statType}
                onChange={handleStatTypeChange}
                className="w-full pl-3 pr-10 py-2 bg-gray-900/50 border border-gray-600 
                         rounded-lg text-white appearance-none focus:border-emerald-500/50 
                         focus:ring-1 focus:ring-emerald-500/20 focus:outline-none"
              >
                {Object.entries(statCategories).map(([key, category]) => (
                  <optgroup key={key} label={category.label} className="bg-gray-800">
                    {category.options.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              <ChevronDown 
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none" 
                size={16} 
              />
            </div>
          </div>

          {/* Value Input */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-400">
              Value
            </label>
            <input
              type="number"
              step="0.5"
              value={formData.value}
              onChange={handleValueChange}
              className="w-full px-3 py-2 bg-gray-900/50 border border-gray-600 
                       rounded-lg text-white focus:border-emerald-500/50 
                       focus:ring-1 focus:ring-emerald-500/20 focus:outline-none"
              placeholder="Enter value"
            />
          </div>

          {/* Over/Under Select */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-400">
              Over/Under
            </label>
            <div className="relative">
              <select
                value={formData.overUnder}
                onChange={handleOverUnderChange}
                className="w-full pl-3 pr-10 py-2 bg-gray-900/50 border border-gray-600 
                         rounded-lg text-white appearance-none focus:border-emerald-500/50 
                         focus:ring-1 focus:ring-emerald-500/20 focus:outline-none"
              >
                <option value="over">Over</option>
                <option value="under">Under</option>
              </select>
              <ChevronDown 
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none" 
                size={16} 
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={!formData.value}
            className="px-6 py-2 bg-emerald-500 text-gray-900 rounded-lg font-medium 
                     hover:bg-emerald-400 transition-colors disabled:opacity-50 
                     disabled:cursor-not-allowed flex items-center gap-2"
          >
            <Calculator size={16} />
            Check Line
          </button>
        </div>
      </form>

      {/* Results */}
      {result && <ResultBadge result={result} />}
    </div>
  );
};

export default LineCheck;