import React, { useState } from 'react';
import { X } from 'lucide-react';

interface FAQItemProps {
  question: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const FAQItem = ({ question, children, isOpen, onToggle }: FAQItemProps) => (
  <div className="border border-[#1A1C24] rounded-xl overflow-hidden mb-4 transition-all duration-300">
    <button
      onClick={onToggle}
      className="w-full px-6 py-4 flex items-center justify-between text-left bg-[#0D0E14] hover:bg-[#1A1C24] transition-colors duration-200"
    >
      <h3 className="text-white text-lg font-medium">{question}</h3>
      <X 
        className={`w-5 h-5 text-white transition-transform duration-300 ${
          isOpen ? 'rotate-0' : 'rotate-45'
        }`}
      />
    </button>
    <div 
      className={`overflow-hidden transition-all duration-300 ${
        isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
      }`}
    >
      <div className="px-6 py-4 text-[#8A8F98] leading-relaxed">
        {children}
      </div>
    </div>
  </div>
);

const FAQSection = () => {
  const [openItem, setOpenItem] = useState<number | null>(null);

  const faqs = [
    {
      id: 1,
      question: "How do I get access to OVERDOG?",
      answer: "Overdog is a dashboard that you can access on the web anywhere in the world. You can gain access by signing up and paying the monthly fee to have access to the software."
    },
    {
      id: 2,
      question: "Does OVERDOG actually work? Can I trust it?",
      answer: "This is a great question and one we recieve often. It's hard for many people to open their mind to the possibility that there's software that exists now that can help you make money easily, and while there is a chance of losing still with an unpredictable element, overtime our AI learns and we chip away little by little at that unpredictability. You can trust that we will work our hardest to ensure we have better technology than the books to get you the superpowers we promise."
    },
    {
      id: 3,
      question: "How do you compare to other AI Sports Betting Prediction sites?",
      answer: `We have looked into other "AI Sports Picks" platforms and have found no evidence that they are using real AI or relevent technology. Instead we have found that they use basic algorithms such as linear regression or basic probability models which have major flaws, are ineffective at predicting the nature of sports, and of which are already used by the books. We have found some using ChatGPT, which is designed for natural language processing and understanding, not for running predictive models. All in all OVERDOG has developed powerful AI models which apply to specific sports and are trained on very specific data in order to take back the edge from the books. Our superior technology gives you as the bettor immense power when betting on sports regardless of some short term losses.`
    },
    {
      id: 4,
      question: "What is AutoBet?",
      answer: "AutoBet is a first of it's kind feature. Using our AI Plays, you can purchase the AutoBet add-on and choose to automatically have bets placed inside of your sportsbook for you, setting the wager amount and choosing the bankroll management strategy you want our software to follow. With the accuracy of our AI Picks and the automation, you could be seeing large growth with doing little to no research or work."
    },
    {
      id: 5,
      question: "Do you have any proof the system works? Any of the past AI picks?",
      answer: "We have a decent amount, however Google wiped our servers in April of 2024, and we lost a year's worth of AI predictions we were gathering to show you. We aim to have completed data by the end of the 2024-2025 NBA season for you to take a look at! For now we have partial data from May 2024 to the present day, which doesn't help, but we aim to publish a complete utility for you to explore this data soon!"
    },
    {
      id: 6,
      question: "Will I be able to get a refund? Can I cancel my subscription? What happens if I chargeback?",
      answer: (
        <>
          <p className="mb-4"><strong>Refunds:</strong><br />
          Due to the nature of our service as a Software as a Service (SaaS) offering, we are unable to provide refunds. Since you're subscribing to ongoing access, you'll be charged for the full subscription period. However, we do offer a first bet reimbursement program up to $25 back for those that lose their first OVERDOG bet. Terms and conditions apply*</p>
          
          <p className="mb-4"><strong>Cancellations:</strong><br />
          The good news is you have complete control over your subscription! You can cancel at any time to avoid future charges. Simply follow the cancellation instructions within your account settings or contact our support team for assistance.</p>
          
          <p><strong>Chargebacks:</strong><br />
          We kindly ask that you refrain from initiating a chargeback. Since subscriptions provide ongoing access, a chargeback would be unsuccessful, and we will charge you $15 extra in service fees for initiating such chargebacks that hurt our business. If you have any questions or concerns about your subscription, please don't hesitate to contact us - we're happy to help!</p>
        </>
      )
    }
  ];

  return (
    <div className="py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-5xl md:text-6xl font-light text-white text-center mb-4">
          Frequently asked questions
        </h2>
        <p className="text-center text-[#8A8F98] mb-12">
          Don't see your answer? Get in touch via our communities.{' '}
          <a href="#" className="text-[#F7D618] hover:underline">Discord</a>
        </p>

        <div className="max-w-3xl mx-auto">
          {faqs.map((faq) => (
            <FAQItem
              key={faq.id}
              question={faq.question}
              isOpen={openItem === faq.id}
              onToggle={() => setOpenItem(openItem === faq.id ? null : faq.id)}
            >
              {faq.answer}
            </FAQItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
