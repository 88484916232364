// statsConfig.js

// Configuration for statistical categories
export const statCategories = {
    basic: {
      label: 'Basic Stats',
      options: [
        { value: 'PTS', label: 'Points' },
        { value: 'AST', label: 'Assists' },
        { value: 'REB', label: 'Rebounds' },
        { value: 'STL', label: 'Steals' },
        { value: 'BLK', label: 'Blocks' },
        { value: 'TOV', label: 'Turnovers' },
        { value: 'FG3M', label: '3-pointers Made' },
        { value: 'PF', label: 'Personal Fouls' }
      ]
    },
    special: {
      label: 'Special Achievements',
      options: [
        { value: 'DD', label: 'Double-double' },
        { value: 'TD', label: 'Triple-double' }
      ]
    },
    pointsCombinations: {
      label: 'Points Combinations',
      options: [
        { value: 'PTS_AST', label: 'Points + Assists' },
        { value: 'PTS_REB', label: 'Points + Rebounds' },
        { value: 'PTS_STL', label: 'Points + Steals' },
        { value: 'PTS_BLK', label: 'Points + Blocks' },
        { value: 'PTS_TOV', label: 'Points + Turnovers' },
        { value: 'PTS_FG3M', label: 'Points + 3-pointers Made' },
        { value: 'PTS_AST_REB', label: 'Points + Assists + Rebounds' },
        { value: 'PTS_STL_BLK', label: 'Points + Steals + Blocks' }
      ]
    },
    assistsCombinations: {
      label: 'Assists Combinations',
      options: [
        { value: 'AST_REB', label: 'Assists + Rebounds' },
        { value: 'AST_STL', label: 'Assists + Steals' },
        { value: 'AST_BLK', label: 'Assists + Blocks' },
        { value: 'AST_TOV', label: 'Assists + Turnovers' },
        { value: 'AST_REB_STL', label: 'Assists + Rebounds + Steals' },
        { value: 'AST_REB_BLK', label: 'Assists + Rebounds + Blocks' }
      ]
    },
    reboundsCombinations: {
      label: 'Rebounds Combinations',
      options: [
        { value: 'REB_STL', label: 'Rebounds + Steals' },
        { value: 'REB_BLK', label: 'Rebounds + Blocks' },
        { value: 'REB_TOV', label: 'Rebounds + Turnovers' },
        { value: 'REB_STL_BLK', label: 'Rebounds + Steals + Blocks' }
      ]
    },
    defensiveCombinations: {
      label: 'Defensive Combinations',
      options: [
        { value: 'STL_BLK', label: 'Steals + Blocks' },
        { value: 'STL_TOV', label: 'Steals + Turnovers' },
        { value: 'BLK_TOV', label: 'Blocks + Turnovers' }
      ]
    }
  };
  
  // Helper function to calculate combined stats
  export const calculateCombinedStats = (game, statType) => {
    // Split the stat type into individual stats (e.g., 'PTS_AST' -> ['PTS', 'AST'])
    const stats = statType.split('_');
    
    // For special cases (DD/TD), handle separately
    if (statType === 'DD' || statType === 'TD') {
      const categories = ['PTS', 'REB', 'AST', 'STL', 'BLK'];
      const doubleDigits = categories.filter(cat => (game[cat] || 0) >= 10);
      if (statType === 'DD') return doubleDigits.length >= 2 ? 1 : 0;
      if (statType === 'TD') return doubleDigits.length >= 3 ? 1 : 0;
    }
    
    // For regular combinations, sum up the values
    return stats.reduce((sum, stat) => {
      // Handle potential null or undefined values
      const value = game[stat] || 0;
      return sum + value;
    }, 0);
  };