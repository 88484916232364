import React, { useState } from 'react';
import { Clock } from 'lucide-react';

// Navigation tabs component
const NavigationTabs = () => {
  const [activeTab, setActiveTab] = useState('variety');
  
  const tabs = [
    { id: 'intuitive', label: 'Intuitive Interface' },
    { id: 'variety', label: 'Variety of Sports and Bets' },
    { id: 'data', label: 'Data Exploration Tools' },
    { id: 'sportsbook', label: 'Sportsbook Connections and Autopilot Betting' },
  ];

  return (
    <div className="flex space-x-4 overflow-x-auto py-2">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => setActiveTab(tab.id)}
          className={`px-4 py-2 rounded-lg whitespace-nowrap text-sm transition-all duration-200
            ${activeTab === tab.id 
              ? 'bg-[#1A1C24] text-white border-b-2 border-[#00F7F7]' 
              : 'text-[#636978] hover:text-white'}`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

// Main platform section component
const PlatformSection = () => {
  return (
    <div className="py-20">
      <div className="container mx-auto px-6">
        {/* Main heading */}
        <h2 className="text-5xl md:text-6xl font-light mb-6 tracking-tight text-white">
          Our Platform Aims to Give You Betting{' '}
          <span className="block" style={{
            background: 'linear-gradient(90deg, #F7D618 0%, #00F7F7 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: '0 0 20px rgba(0,247,247,0.2)'
          }}>
            Superpowers
          </span>
        </h2>

        {/* Description text */}
        <p className="text-[#8A8F98] text-xl max-w-3xl text-center mx-auto mb-12 leading-relaxed">
          Backed by years of data, and the best Sports Predicting AI models in 
          the world, custom built by our exceptional team of AI engineers and 
          data scientists, we achieve above 75% accuracy consistently.
        </p>

        {/* Navigation tabs */}
        <NavigationTabs />

        {/* Dashboard preview (placeholder) */}
        <div className="mt-8 rounded-2xl border border-[#2A2D36] bg-[#0D0E14] p-6">
          <div className="text-white">Dashboard content goes here...</div>
        </div>

      </div>
    </div>
  );
};

export default PlatformSection;
