import { useState } from 'react';
import { PlayerSearchParams } from '../../types/nbaPlayerStats';
import type { PlayerStats, PlayerStatsResponse, SeasonStatistics, PlayerGameLog } from '../../types/nbaPlayerStats';
import SearchForm from '../../components/dashboard/basketball/PropsExplorer/player/SearchForm';
import PlayerProfile from '../../components/dashboard/basketball/PropsExplorer/player/PlayerProfile';
import StatisticsGrid from '../../components/dashboard/basketball/PropsExplorer/player/StatisticsGrid';
import GameLog from '../../components/dashboard/basketball/PropsExplorer/player/GameLog';
import LineCheck from '../../components/dashboard/basketball/PropsExplorer/player/LineCheck';
import { Alert } from '../../components/dashboard/basketball/PropsExplorer/ui/Alert';

const transformToSeasonStats = (stats: PlayerGameLog[]): SeasonStatistics => {
  if (!stats || stats.length === 0) {
    return {
      ppg: 0,
      apg: 0,
      rpg: 0,
      spg: 0,
      bpg: 0,
      threePm: 0,
      tov: 0,
      pf: 0,
      gamesPlayed: 0,
      ppgChange: 0,
      apgChange: 0,
      rpgChange: 0,
      spgChange: 0,
      bpgChange: 0,
      threePmChange: 0,
      tovChange: 0,
      pfChange: 0
    };
  }

  const totalGames = stats.length;
  const recentGames = stats.slice(0, 10);
  const previousGames = stats.slice(10, 20);

  const calculateAverage = (games: PlayerGameLog[], stat: keyof PlayerGameLog) => {
    const total = games.reduce((sum, game) => {
      const value = game[stat];
      return sum + (typeof value === 'number' ? value : 0);
    }, 0);
    return games.length > 0 ? total / games.length : 0;
  };

  const calculateChange = (recent: number, previous: number) => {
    if (previous === 0) return 0;
    return ((recent - previous) / previous) * 100;
  };

  const ppg = calculateAverage(stats, 'PTS');
  const apg = calculateAverage(stats, 'AST');
  const rpg = calculateAverage(stats, 'REB');
  const spg = calculateAverage(stats, 'STL');
  const bpg = calculateAverage(stats, 'BLK');
  const threePm = calculateAverage(stats, 'FG3M');
  const tov = calculateAverage(stats, 'TOV');
  const pf = calculateAverage(stats, 'PF');

  // Ensure we have valid PlayerGameLog data
  if (stats.length > 0 && !stats[0].PLAYER_ID) {
    throw new Error('Invalid PlayerGameLog data structure');
  }

  const ppgChange = calculateChange(calculateAverage(recentGames, 'PTS'), calculateAverage(previousGames, 'PTS'));
  const apgChange = calculateChange(calculateAverage(recentGames, 'AST'), calculateAverage(previousGames, 'AST'));
  const rpgChange = calculateChange(calculateAverage(recentGames, 'REB'), calculateAverage(previousGames, 'REB'));
  const spgChange = calculateChange(calculateAverage(recentGames, 'STL'), calculateAverage(previousGames, 'STL'));
  const bpgChange = calculateChange(calculateAverage(recentGames, 'BLK'), calculateAverage(previousGames, 'BLK'));
  const threePmChange = calculateChange(calculateAverage(recentGames, 'FG3M'), calculateAverage(previousGames, 'FG3M'));
  const tovChange = calculateChange(calculateAverage(recentGames, 'TOV'), calculateAverage(previousGames, 'TOV'));
  const pfChange = calculateChange(calculateAverage(recentGames, 'PF'), calculateAverage(previousGames, 'PF'));

  return {
    ppg,
    apg,
    rpg,
    spg,
    bpg,
    threePm,
    tov,
    pf,
    ppgChange,
    apgChange,
    rpgChange,
    spgChange,
    bpgChange,
    threePmChange,
    tovChange,
    pfChange,
    gamesPlayed: totalGames
  };
};

interface PlayerData {
  stats: PlayerGameLog[];
  seasons: string[];
  player_id: number;
  team: string;
  position: string;
  basicInfo: {
    firstName: string;
    lastName: string;
    height: string;
    weight: string;
    jerseyNumber: string;
  };
  error?: string;
}

import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getPlayerStats, getPlayerCareerInfo, getPlayerProps } from '../../services/api';

interface PlayerViewProps {
  playerId?: string;
}

const PlayerView = ({ playerId: propPlayerId }: PlayerViewProps) => {
  const paramsPlayerId = useParams<{ playerId: string }>().playerId;
  const playerId = propPlayerId || paramsPlayerId;
  const [playerData, setPlayerData] = useState<PlayerData | null>(null);
  const [playerStats, setPlayerStats] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProps, setIsLoadingProps] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState('2023-24');

  const fetchPlayerData = async (season: string) => {
    if (!playerId) return;
    
    try {
      setIsLoading(true);
      const [statsData, careerData] = await Promise.all([
        getPlayerStats(playerId, season),
        getPlayerCareerInfo(playerId)
      ]);

      // Validate stats data exists
      if (!statsData?.stats || !Array.isArray(statsData.stats) || statsData.stats.length === 0) {
        throw new Error('No game stats available for this player');
      }

      // Validate and normalize team data with better fallbacks
      const team = (careerData?.team?.name || 
        statsData.stats[0]?.team?.name || 
        statsData.stats[0]?.homeTeam?.name || 
        statsData.stats[0]?.awayTeam?.name || 
        'Unknown Team');


      setPlayerData({
        stats: statsData.stats,
        seasons: statsData.seasons,
        player_id: Number(playerId),
        team: team,
        position: careerData?.position || '',
        basicInfo: {
          firstName: careerData?.basicInfo?.firstName || '',
          lastName: careerData?.basicInfo?.lastName || '',
          height: careerData?.basicInfo?.height || '',
          weight: careerData?.basicInfo?.weight || '',
          jerseyNumber: careerData?.basicInfo?.jerseyNumber || ''
        }
      });
      setError(null);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(`Failed to fetch player data: ${err.message}`);
      } else {
        setError('Failed to fetch player data: Unknown error');
      }
      console.error('Error fetching player data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPlayerData(selectedSeason);
  }, [playerId, selectedSeason]);

  const handleSeasonChange = (season: string) => {
    setSelectedSeason(season);
  };

  const handleSearch = async (playerInfo: PlayerSearchParams) => {
    try {
      setIsLoading(true);
      setIsLoadingProps(true);
      const stats = await getPlayerProps(playerInfo.playerId, selectedSeason);
      setPlayerStats(stats);
      const params = new URLSearchParams({
        query: playerInfo.name,
        season: playerInfo.season
      });

      const response = await fetch(`${import.meta.env.VITE_RENDER_LINK_PROPS_BACKEND}/player/stats?${params}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch player stats');
      }
      
      const data = await response.json();
      setPlayerData({
        stats: data.stats,
        seasons: data.seasons,
        player_id: data.player_id || 0,
        team: data.team || '',
        position: data.position || '',
        basicInfo: {
          firstName: data.first_name || '',
          lastName: data.last_name || '',
          height: data.height || '',
          weight: data.weight || '',
          jerseyNumber: data.jersey_number || ''
        }
      });
      setError(null);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred while fetching player stats');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#0a0a0f] text-white">
      <div className="max-w-7xl mx-auto px-4 py-8 space-y-8">
        

        {error && (
          <Alert variant="destructive">
            Failed to fetch player stats: {error}
          </Alert>
        )}

        {playerData && (
          <div className="space-y-8 animate-fadeIn">
            <PlayerProfile 
              playerData={playerData}
              onFollow={() => {}}
              onShare={() => {}}
            />
            <StatisticsGrid 
              stats={playerData.stats} 
              onStatClick={(stat) => console.log('Stat clicked:', stat)}
            />
            <LineCheck
              games={playerData.stats}
              onStatChange={(stat: string) => console.log('Stat changed:', stat)}
              onLineValueChange={(value: number) => console.log('Line value changed:', value)}
              initialStat="PTS"
            />
            <GameLog
              games={playerData.stats as PlayerGameLog[]}
              highlightedStat="PTS"
              lineValue={0}
              overUnder="over"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerView;
