import type { PlayerStatsResponse } from '../../../../../types/nbaPlayerStats';

interface PlayerProfileProps {
  playerData: PlayerStatsResponse & {
    team: string;
    position: string;
    basicInfo: {
      firstName: string;
      lastName: string;
      height: string;
      weight: string;
      jerseyNumber: string;
    };
  };
  onFollow: () => void;
  onShare: () => void;
}

const PlayerProfile = ({ playerData, onFollow, onShare }: PlayerProfileProps) => {
  const fullName = `${playerData.basicInfo.firstName} ${playerData.basicInfo.lastName}`;
  
  return (
    <div className="bg-[#1a1a1f] p-6 rounded-lg">
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <h2 className="text-2xl font-semibold">{fullName}</h2>
          <div className="text-gray-400">
            {playerData.team} • {playerData.position}
          </div>
          <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
            <div>Height: {playerData.basicInfo.height}</div>
            <div>Weight: {playerData.basicInfo.weight}</div>
            <div>Jersey: #{playerData.basicInfo.jerseyNumber}</div>
          </div>
        </div>
        <div className="flex gap-2">
          <button 
            onClick={onFollow}
            className="px-4 py-2 bg-[#2cf583] text-black rounded-md hover:bg-[#25c46a] transition-colors"
          >
            Follow
          </button>
          <button 
            onClick={onShare}
            className="px-4 py-2 bg-white/10 rounded-md hover:bg-white/20 transition-colors"
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlayerProfile;
