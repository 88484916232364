import React from 'react';

const CTASection = () => {
  return (
    <div className="py-32 text-center">
      <div className="container mx-auto px-6">
        <h2 className="text-5xl md:text-7xl font-light mb-16 leading-tight">
          <div className="text-white">
            Time to remove emotions,
            <br />
            Start betting smarter,
            <br />
            and{' '}
          </div>
          <span style={{
            background: 'linear-gradient(90deg, #F7D618 0%, #00F7A8 50%, #00F7F7 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: '0 0 20px rgba(0,247,247,0.2)'
          }}>
            GET IN THE GREEN.
          </span>
        </h2>

        <a href="/auth"
          className="group relative px-4 py-2 rounded-full text-sm font-medium 
            flex items-center justify-center mx-auto transition-all duration-300 max-w-[200px]"
          style={{
            background: 'linear-gradient(90deg, rgba(0,247,247,0.1) 0%, rgba(247,214,24,0.1) 100%)',
            border: '1px solid rgba(42,45,54,0.8)',
          }}
        >
          {/* Button glow effects */}
          <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            style={{
              background: 'linear-gradient(90deg, rgba(0,247,247,0.2) 0%, rgba(247,214,24,0.2) 100%)',
              filter: 'blur(4px)'
            }}
          />

          {/* Button content */}
          <div className="relative flex items-center text-white">
            <span>Start Winning</span>
            <span className="ml-2 transform translate-x-0 group-hover:translate-x-1 transition-transform duration-200">
              →
            </span>
          </div>

          {/* Bottom gradient line */}
          <div className="absolute bottom-0 left-[5%] right-[5%] h-[1px]"
            style={{
              background: 'linear-gradient(90deg, transparent 0%, rgba(0,247,247,0.3) 50%, transparent 100%)'
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default CTASection;
