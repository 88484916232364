export interface TeamInfo {
  name: string;
  id: number;
}

export const TEAM_NAMES: Record<string, TeamInfo> = {
  'ATL': { name: 'Atlanta Hawks', id: 1610612737 },
  'BOS': { name: 'Boston Celtics', id: 1610612738 },
  'CLE': { name: 'Cleveland Cavaliers', id: 1610612739 },
  'NOP': { name: 'New Orleans Pelicans', id: 1610612740 },
  'CHI': { name: 'Chicago Bulls', id: 1610612741 },
  'DAL': { name: 'Dallas Mavericks', id: 1610612742 },
  'DEN': { name: 'Denver Nuggets', id: 1610612743 },
  'GSW': { name: 'Golden State Warriors', id: 1610612744 },
  'HOU': { name: 'Houston Rockets', id: 1610612745 },
  'LAC': { name: 'LA Clippers', id: 1610612746 },
  'LAL': { name: 'Los Angeles Lakers', id: 1610612747 },
  'MIA': { name: 'Miami Heat', id: 1610612748 },
  'MIL': { name: 'Milwaukee Bucks', id: 1610612749 },
  'MIN': { name: 'Minnesota Timberwolves', id: 1610612750 },
  'BKN': { name: 'Brooklyn Nets', id: 1610612751 },
  'NYK': { name: 'New York Knicks', id: 1610612752 },
  'ORL': { name: 'Orlando Magic', id: 1610612753 },
  'IND': { name: 'Indiana Pacers', id: 1610612754 },
  'PHI': { name: 'Philadelphia 76ers', id: 1610612755 },
  'PHX': { name: 'Phoenix Suns', id: 1610612756 },
  'POR': { name: 'Portland Trail Blazers', id: 1610612757 },
  'SAC': { name: 'Sacramento Kings', id: 1610612758 },
  'SAS': { name: 'San Antonio Spurs', id: 1610612759 },
  'OKC': { name: 'Oklahoma City Thunder', id: 1610612760 },
  'TOR': { name: 'Toronto Raptors', id: 1610612761 },
  'UTA': { name: 'Utah Jazz', id: 1610612762 },
  'MEM': { name: 'Memphis Grizzlies', id: 1610612763 },
  'WAS': { name: 'Washington Wizards', id: 1610612764 },
  'DET': { name: 'Detroit Pistons', id: 1610612765 },
  'CHA': { name: 'Charlotte Hornets', id: 1610612766 }
};
