import type { PlayerStatsResponse } from '../../../../../types/nbaPlayerStats';

interface StatisticsGridProps {
  stats: PlayerStatsResponse['stats'];
  onStatClick: (stat: string) => void;
}

interface Averages {
  PTS: number;
  AST: number;
  REB: number;
  STL: number;
  BLK: number;
  TOV: number;
  FG_PCT: number;
  FG3_PCT: number;
  FT_PCT: number;
}

const calculateAverages = (games: PlayerStatsResponse['stats']): Averages | null => {
  if (!games || !games.length) return null;

  const totalGames = games.length;
  const initialSums: Averages = {
    PTS: 0,
    AST: 0,
    REB: 0,
    STL: 0,
    BLK: 0,
    TOV: 0,
    FG_PCT: 0,
    FG3_PCT: 0,
    FT_PCT: 0
  };

  const sums = games.reduce<Averages>((acc, game) => ({
    PTS: acc.PTS + (game.PTS || 0),
    AST: acc.AST + (game.AST || 0),
    REB: acc.REB + (game.REB || 0),
    STL: acc.STL + (game.STL || 0),
    BLK: acc.BLK + (game.BLK || 0),
    TOV: acc.TOV + (game.TOV || 0),
    FG_PCT: acc.FG_PCT + (game.FG_PCT || 0),
    FG3_PCT: acc.FG3_PCT + (game.FG3_PCT || 0),
    FT_PCT: acc.FT_PCT + (game.FT_PCT || 0),
  }), initialSums);

  return {
    PTS: Number((sums.PTS / totalGames).toFixed(1)),
    AST: Number((sums.AST / totalGames).toFixed(1)),
    REB: Number((sums.REB / totalGames).toFixed(1)),
    STL: Number((sums.STL / totalGames).toFixed(1)),
    BLK: Number((sums.BLK / totalGames).toFixed(1)),
    TOV: Number((sums.TOV / totalGames).toFixed(1)),
    FG_PCT: Number((sums.FG_PCT / totalGames).toFixed(1)),
    FG3_PCT: Number((sums.FG3_PCT / totalGames).toFixed(1)),
    FT_PCT: Number((sums.FT_PCT / totalGames).toFixed(1)),
  };
};

const StatisticsGrid = ({ stats, onStatClick }: StatisticsGridProps) => {
  const averages = calculateAverages(stats);
  
  if (!averages) return null;

  const statCategories = [
    { label: 'Points', value: averages.PTS, key: 'PTS' },
    { label: 'Rebounds', value: averages.REB, key: 'REB' },
    { label: 'Assists', value: averages.AST, key: 'AST' },
    { label: 'Steals', value: averages.STL, key: 'STL' },
    { label: 'Blocks', value: averages.BLK, key: 'BLK' },
    { label: 'Turnovers', value: averages.TOV, key: 'TOV' },
    { label: 'FG%', value: averages.FG_PCT, key: 'FG_PCT' },
    { label: '3P%', value: averages.FG3_PCT, key: 'FG3_PCT' },
    { label: 'FT%', value: averages.FT_PCT, key: 'FT_PCT' },
  ];

  return (
    <div className="grid grid-cols-3 gap-4">
      {statCategories.map((stat) => (
        <button
          key={stat.key}
          onClick={() => onStatClick(stat.key)}
          className="bg-[#1a1a1f] p-4 rounded-lg hover:bg-[#2a2a2f] transition-colors"
        >
          <div className="text-2xl font-bold">
            {typeof stat.value === 'number' ? stat.value.toFixed(1) : stat.value}
          </div>
          <div className="text-gray-400 text-sm">{stat.label}</div>
        </button>
      ))}
    </div>
  );
};

export default StatisticsGrid;
