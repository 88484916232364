import React from 'react';
import { ArrowRight } from 'lucide-react';

const HeroContent = () => {
  return (
    <div className="w-full md:w-[45%] mb-12 md:mb-0 relative px-4 md:px-0 md:min-h-[600px] flex flex-col justify-center">
      {/* Enhanced glow effects */}
      <div className="absolute -inset-10 bg-[#5983f0] opacity-[0.02] blur-2xl rounded-full" />
      <div className="absolute -inset-20 bg-gradient-to-tr from-[#00F7F7]/3 to-[#F7D618]/3 blur-2xl rounded-[100px] rotate-12" />
      
      {/* Main Heading */}
      <h1 
        className="text-[42px] md:text-[72px] mb-8 md:mb-12 !font-normal leading-[1.1] md:leading-[1] !text-left"
        style={{
          fontFamily: '"PolySans Neutral", sans-serif',
          fontWeight: 400,
          fontStyle: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.2em',
          textDecoration: 'none',
          textTransform: 'none',
          filter: 'drop-shadow(-5px -5px 35px #5983f0)',
        }}
      >
        <div className="text-white text-left">
          We're Not Just 
          <br className="hidden md:block" /> Ahead of The 
          <br className="hidden md:block" /> Game.{' '}
          <span 
            style={{
              background: 'linear-gradient(100deg, rgb(255, 232, 114) 0%, rgb(0, 255, 255) 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textShadow: '-4px 10px 15px rgba(0, 0, 0, .2), 27px 16px 51px rgba(0, 255, 255, .3), -20px -16px 44px rgba(255, 232, 114, .3)',
            }}
          >
            We're Rewriting The Rules.
          </span>
        </div>
      </h1>
      
      {/* Floating Text Section */}
      <div 
        className="space-y-2 md:space-y-3 mb-8 md:mb-10 relative py-2 md:py-3 rounded-2xl text-left md:max-w-[500px]"
        style={{
          background: 'rgba(0, 0, 0, 0.05)',
          backdropFilter: 'blur(8px)',
          WebkitBackdropFilter: 'blur(8px)',
        }}
      >
        <p 
          className="text-[rgb(255,232,114)] text-base md:text-lg tracking-wide font-light"
          style={{ 
            textShadow: '0 0 20px rgba(0, 255, 255, 0.5)',
            animation: 'floatText 6s ease-in-out infinite',
          }}
        >
          Authentic
        </p>
        <p 
          className="text-[rgb(0,255,255)] text-lg tracking-wide font-light"
          style={{ 
            textShadow: '0 0 20px rgba(255, 232, 114, 0.5)',
            animation: 'floatText 6s ease-in-out infinite reverse',
          }}
        >
          Unmatched Accuracy
        </p>
        <p 
          className="text-[rgb(255,232,114)] text-lg tracking-wide font-light"
          style={{ 
            textShadow: '0 0 20px rgba(0, 255, 255, 0.5)',
            animation: 'floatText 6s ease-in-out infinite',
            animationDelay: '-2s',
          }}
        >
          You Can Trust
        </p>
      </div>

      {/* CTA Button */}
      <a 
        href="/auth"
        className="group inline-flex items-center px-8 md:px-10 py-3.5 md:py-4 text-base md:text-lg font-medium text-white rounded-full bg-black border border-[rgba(255,255,255,0.1)] hover:border-[rgba(255,255,255,0.2)] transition-all duration-200 hover:translate-y-[-2px]"
        style={{
          background: 'rgba(0, 0, 0)',
          backdropFilter: 'blur(8px)',
          border:'2px solid white',
          WebkitBackdropFilter: 'blur(8px)',
          filter: 'drop-shadow(-3px -3px 25px #5983f0)',
          boxShadow: '0 10px 30px rgba(0,0,0,0.3)',
        }}
      >
        <span>Start Winning</span>
        <ArrowRight className="ml-3 w-5 h-5 transform translate-x-0 group-hover:translate-x-1 transition-transform duration-200" strokeWidth={2.5} />
      </a>

      <style>
        {`
          @keyframes floatText {
            0% { transform: translateX(0px); }
            25% { transform: translateX(20px); }
            75% { transform: translateX(-20px); }
            100% { transform: translateX(0px); }
          }
        `}
      </style>
    </div>
  );
};

export default HeroContent;
