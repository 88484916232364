import axios from 'axios';
import { TEAM_NAMES } from '../constants/teams';

const API_BASE_URL = import.meta.env.VITE_RENDER_LINK_PROPS_BACKEND || 'https://api.example.com';

interface ApiResponse<T> {
  data: T;
  status: number;
}

export interface PlayerSuggestion {
  id: number;
  full_name: string;
  team_id: number | null;
  first_name?: string;
  last_name?: string;
}

interface Player {
  id: number;
  first_name: string;
  last_name: string;
}

interface CareerInfoResponse {
  [key: string]: any;
}

interface PlayerGameLog {
  PLAYER_ID: number;
  PLAYER_NAME: string;
  PROP_TYPE: string;
  LINE: number;
  ODDS: string;
  TEAM_ABBREVIATION: string;
  GAME_ID: string;
  GAME_DATE: string;
  MATCHUP: string;
  WL: string;
  MIN: string;
  FGM: number;
  FGA: number;
  FG_PCT: number;
  FG3M: number;
  FG3A: number;
  FG3_PCT: number;
  FTM: number;
  FTA: number;
  FT_PCT: number;
  OREB: number;
  DREB: number;
  REB: number;
  AST: number;
  STL: number;
  BLK: number;
  TOV: number;
  PF: number;
  PTS: number;
  PLUS_MINUS: number;
  VIDEO_AVAILABLE: number;
  DD: 'YES' | 'NO';
  TD: 'YES' | 'NO';
  [key: string]: any;
}

interface CommonPlayerInfo {
  first_name: string;
  last_name: string;
  draft_year: string;
  draft_round: string;
  draft_number: string;
  first_nba_game: string;
  college: string;
}

interface PlayerCareerStats {
  SeasonTotalsRegularSeason: Array<{
    season: string;
    [key: string]: any;
  }>;
}

interface PlayerStatsResponse {
  seasons: string[];
  stats: PlayerGameLog[];
}

interface NextGameResponse {
  nextGame: {
    opponent: string;
    date: string;
  } | null;
}

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getPlayerSuggestions = async (query: string): Promise<PlayerSuggestion[]> => {
  try {
    const response = await api.get<PlayerSuggestion[]>(
      '/api/autocomplete',
      { params: { query } }
    );
    console.log('API Request:', {
      url: `${API_BASE_URL}/api/autocomplete`,
      params: { query }
    });
    console.log('API Response:', response.data);
    return response.data || [];
  } catch (error) {
    throw new Error('Failed to fetch player suggestions');
  }
};

// Utility function to parse MATCHUP field and get team info
const parseMatchup = (matchup: string) => {
  if (!matchup) return { homeTeam: null, awayTeam: null, isHomeGame: false };
  
  const [teams, location] = matchup.split(' ');
  const [team1, team2] = teams.split(/vs\.|@/);
  
  const isHomeGame = location === 'vs.';
  return {
    homeTeam: isHomeGame ? team1 : team2,
    awayTeam: isHomeGame ? team2 : team1,
    isHomeGame
  };
};

export const getPlayerStats = async (playerId: string, season: string): Promise<PlayerStatsResponse> => {
  try {
    // Ensure season is in YYYY-YY format
    const formattedSeason = season.length === 4 ? 
      `${season}-${(parseInt(season) + 1).toString().slice(-2)}` : 
      season;
      
    const fullUrl = `${API_BASE_URL}/api/player/stats?player_id=${playerId}&season=${formattedSeason}`;
    console.log('Full API Request URL:', fullUrl);
    
    const response = await api.get<{ 
      seasons?: string[];
      stats?: PlayerGameLog[];
    }>('/api/player/stats', {
      params: { player_id: playerId, season: formattedSeason }
    });
    
    console.log('API Request Details:', {
      url: `${API_BASE_URL}/api/player/stats`,
      params: { player_id: playerId, season: formattedSeason },
      headers: response.config.headers,
      status: response.status
    });
    console.log('API Response:', {
      status: response.status,
      data: response.data,
      headers: response.headers,
      config: response.config
    });
    console.log('Response Data Structure:', {
      hasSeasons: !!response.data?.seasons,
      hasStats: !!response.data?.stats,
      statsCount: response.data?.stats?.length || 0
    });

    // Validate and normalize response data
    if (!response.data) {
      console.warn('API response data is empty');
      return {
        seasons: [],
        stats: []
      };
    }
    
    // Ensure stats is an array with proper structure and team info
    const stats = (Array.isArray(response.data.stats) ? response.data.stats : []).map(stat => {
      const { homeTeam, awayTeam, isHomeGame } = parseMatchup(stat.MATCHUP);
      
      return {
      ...stat,
      team: stat.team || null,
      PLAYER_ID: stat.PLAYER_ID || Number(playerId),
      PLAYER_NAME: stat.PLAYER_NAME || '',
      TEAM_ABBREVIATION: stat.TEAM_ABBREVIATION || '',
      GAME_ID: stat.GAME_ID || '',
      GAME_DATE: stat.GAME_DATE || '',
      MATCHUP: stat.MATCHUP || '',
      WL: stat.WL || '',
      MIN: stat.MIN || '0',
      FGM: stat.FGM || 0,
      FGA: stat.FGA || 0,
      FG_PCT: stat.FG_PCT || 0,
      FG3M: stat.FG3M || 0,
      FG3A: stat.FG3A || 0,
      FG3_PCT: stat.FG3_PCT || 0,
      FTM: stat.FTM || 0,
      FTA: stat.FTA || 0,
      FT_PCT: stat.FT_PCT || 0,
      OREB: stat.OREB || 0,
      DREB: stat.DREB || 0,
      REB: stat.REB || 0,
      AST: stat.AST || 0,
      STL: stat.STL || 0,
      BLK: stat.BLK || 0,
      TOV: stat.TOV || 0,
      PF: stat.PF || 0,
      PTS: stat.PTS || 0,
      PLUS_MINUS: stat.PLUS_MINUS || 0,
      VIDEO_AVAILABLE: stat.VIDEO_AVAILABLE || 0,
      DD: stat.DD || 'NO',
      TD: stat.TD || 'NO',
      homeTeam: homeTeam ? TEAM_NAMES[homeTeam] ?? null : null,
      awayTeam: awayTeam ? TEAM_NAMES[awayTeam] ?? null : null,
      isHomeGame
    };
    });
    
    // Ensure seasons is an array, default to empty array if not
    const seasons = Array.isArray(response.data.seasons) ? response.data.seasons : [];

    return {
      seasons,
      stats
    };
  } catch (error) {
    if (error && typeof error === 'object') {
      if ('isAxiosError' in error && error.isAxiosError && 'message' in error) {
        const responseData = 
          'response' in error && 
          typeof error.response === 'object' && 
          error.response !== null &&
          'data' in error.response ? error.response.data : undefined;
          
        const responseStatus = 
          'response' in error && 
          typeof error.response === 'object' && 
          error.response !== null &&
          'status' in error.response ? error.response.status : undefined;

        const axiosError = {
          message: String(error.message),
          code: 'code' in error ? String(error.code) : undefined,
          response: {
            data: responseData,
            status: responseStatus
          }
        };
        console.error('API Error Details:', axiosError);
      } else if (error instanceof Error) {
        console.error('Non-Axios Error:', error.message);
      } else {
        console.error('Unknown Error:', error);
      }
    }
    throw new Error('Failed to fetch player stats: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
};

export const getPlayerCareerInfo = async (playerId: string): Promise<CareerInfoResponse> => {
  try {
    const response = await api.get<ApiResponse<CareerInfoResponse>>(
      '/api/player/career',
      { params: { player_id: playerId } }
    );
    return response.data.data;
  } catch (error) {
    throw new Error('Failed to fetch player career info');
  }
};

export const getSeasons = async (): Promise<string[]> => {
  try {
    const response = await api.get<ApiResponse<string[]>>('/api/seasons');
    return response.data.data;
  } catch (error) {
    throw new Error('Failed to fetch seasons');
  }
};

export const getPlayerProps = async (playerId: string, season: string): Promise<any[]> => {
  try {
    const shortSeason = season.substring(5, 7); // Convert "2024-25" to "25"
    const response = await api.get<any[]>(
      '/player/props',
      { params: { playerId, season: shortSeason } }
    );
    console.log(response)
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch player props');
  }
};

export const getNextGame = async (team: string): Promise<NextGameResponse> => {
  try {
    const response = await api.get<ApiResponse<NextGameResponse>>(
      '/api/next-game',
      { params: { team } }
    );
    return response.data.data;
  } catch (error) {
    throw new Error('Failed to fetch next game');
  }
};

export default api;
