import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { advancedLessons } from './AdvancedStrategiesContent';
import { basicLessons } from './BasicsOfSportsBettingContent';

interface Lesson {
  title: string;
  content: string;
}

interface CourseContentPopupProps {
  onClose: () => void;
  courseType: 'basic' | 'advanced';
  lessons?: Lesson[];
}

const CourseCompletionPopup: React.FC<CourseContentPopupProps> = ({ onClose, courseType }) => {
  const courseName = courseType === 'basic' ? 'Basics of Sports Betting' : 'Advanced Strategies';
  return (
    <motion.div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
      style={{ 
        backdropFilter: 'blur(4px)',
        zIndex: 10001
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className="bg-slate-800 rounded-lg w-full max-w-lg p-8 text-center"
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0 }}
        transition={{ type: "spring", duration: 0.5 }}
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.3 }}
          className="w-20 h-20 mx-auto mb-6 bg-green-500 rounded-full flex items-center justify-center"
        >
          <svg 
            className="w-12 h-12 text-white" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M5 13l4 4L19 7" 
            />
          </svg>
        </motion.div>
        
        <motion.h2 
          className="text-3xl font-bold text-white mb-4"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          Congratulations! 🎉
        </motion.h2>
        
        <motion.p 
          className="text-slate-300 mb-6"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          You've successfully completed the {courseType === 'basic' ? 'Basics of Sports Betting' : 'Advanced Strategies'} course!
          Ready to take your knowledge to the next level?
        </motion.p>
        
        <motion.div 
          className="space-y-4"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          <button
            className="w-full bg-green-600 hover:bg-green-700 text-white font-medium py-3 px-6 rounded-lg transition-colors"
            onClick={() => {
              onClose();
            }}
          >
            Start Next Course
          </button>
          
          <button
            className="w-full bg-slate-700 hover:bg-slate-600 text-white font-medium py-3 px-6 rounded-lg transition-colors"
            onClick={onClose}
          >
            Return to Dashboard
          </button>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};


const CourseContentPopup: React.FC<CourseContentPopupProps> = ({ onClose, courseType, lessons }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showCompletion, setShowCompletion] = useState(false);
  const currentLessons = lessons || (courseType === 'advanced' ? advancedLessons : basicLessons);

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(0, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(currentLessons.length - 1, prev + 1));
  };

  const handleComplete = () => {
    setShowCompletion(true);
  };

  const handleCloseAll = () => {
    setShowCompletion(false);
    onClose();
  };

  return (
    <>
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
        style={{ 
          backdropFilter: 'blur(4px)',
          zIndex: 10000
        }}
      >
        <div className="bg-slate-800 rounded-lg w-full max-w-2xl p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-white">Course Content</h2>
            <button 
              onClick={onClose}
              className="text-slate-400 hover:text-white transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div className="space-y-4 text-slate-300">
            <h3 className="text-xl font-semibold text-white mb-4">{currentLessons[currentPage].title}</h3>
            <div className="whitespace-pre-wrap">
              {currentLessons[currentPage].content}
            </div>
            
            <div className="flex justify-between mt-8">
              <button
                className={`px-4 py-2 rounded-lg transition-colors ${
                  currentPage > 0 
                    ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                    : 'bg-gray-600 text-gray-400 cursor-not-allowed'
                }`}
                onClick={handlePrevPage}
                disabled={currentPage === 0}
              >
                Previous Lesson
              </button>
              
              <button
                className={`px-4 py-2 rounded-lg transition-colors ${
                  currentPage < currentLessons.length - 1
                    ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                    : 'bg-gray-600 text-gray-400 cursor-not-allowed'
                }`}
                onClick={handleNextPage}
                disabled={currentPage === currentLessons.length - 1}
              >
                Next Lesson
              </button>
            </div>

            <div className="text-center mt-4 text-slate-400">
              Lesson {currentPage + 1} of {currentLessons.length}
            </div>
            
            {currentPage === currentLessons.length - 1 && (
              <button
                className="mt-6 w-full bg-green-600 hover:bg-green-700 text-white font-medium py-3 px-6 rounded-lg transition-colors"
                onClick={handleComplete}
              >
                Complete Course
              </button>
            )}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showCompletion && (
          <CourseCompletionPopup onClose={handleCloseAll} courseType={courseType} />
        )}
      </AnimatePresence>
    </>
  );
};

export default CourseContentPopup;