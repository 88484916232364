import React from 'react';

const Navbar = () => {
  return (
    <nav className="fixed top-0 left-0 right-0 px-4 md:px-6 py-4 md:py-5 z-50 bg-[rgba(0,0,0,0.3)] backdrop-blur-sm">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <img src="/logo.png" alt="Overdog Logo" width="160" height="40" className="py-1" />
        </div>
        
        <div className="flex items-center">
          <div className="hidden md:flex items-center space-x-12 mr-10">
            <a href="#" className="text-white/80 text-base font-light hover:text-white transition-colors duration-200">
              Affiliates
            </a>
            <a href="#" className="text-white/80 text-base font-light hover:text-white transition-colors duration-200">
              Roadmap
            </a>
            <a href="#" className="text-white/80 text-base font-light hover:text-white transition-colors duration-200">
              Blog
            </a>
            <a href="#" className="text-white/80 text-base font-light hover:text-white transition-colors duration-200">
              Pricing
            </a>
          </div>
          <a 
            href="/auth" 
            className="px-6 py-2.5 rounded-full font-medium text-base text-white bg-black border border-[rgba(255,255,255,0.1)] hover:border-[rgba(255,255,255,0.2)] transition-all duration-200 hover:translate-y-[-2px]"
            style={{
              background: 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(8px)',
              WebkitBackdropFilter: 'blur(8px)',
            }}
          >
            Become an Overdog
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
