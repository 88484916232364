import { useEffect, useState } from 'react';
import PlayerSearch from '../../components/dashboard/basketball/PropsExplorer/PlayerSearch';
import SearchForm from '../../components/dashboard/basketball/PropsExplorer/player/SearchForm';
import { useParams } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { PlayerStats } from '../../types/nbaPlayerStats';
import { PlayerSearchParams } from '../../types/nbaPlayerStats';
import { getPlayerStats, getPlayerCareerInfo } from '../../services/api';
import { TEAM_NAMES, TeamInfo } from '../../constants/teams';
import usePlayerStats from '../../hooks/usePlayerStats';
import PlayerView from './PlayerView';

interface FormattedPlayerStats extends PlayerStats {
  PLAYER_ID: number;
  PLAYER_NAME: string;
  PROP_TYPE: string;
  LINE: number;
  ODDS: string;
  TEAM_ABBREVIATION: string;
}

const PropExplorerNBAPage: React.FC = () => {
  const { playerId: urlPlayerId } = useParams<{ playerId?: string }>();
  const { profile } = useUser();
  const [playerStats, setPlayerStats] = useState<FormattedPlayerStats[]>([]);
  const { stats, loading, error, setSearchParams } = usePlayerStats();
  const [selectedPlayerId, setSelectedPlayerId] = useState<string | null>(urlPlayerId || null);
  const [selectedSeason, setSelectedSeason] = useState('2023-24');

  const handleSearch = ({ playerId, name, season }: PlayerSearchParams) => {
    // Only update search params if they've actually changed
    const newPlayerId = playerId || '';
    const newSeason = season || selectedSeason;
    
    if (newPlayerId !== selectedPlayerId || newSeason !== selectedSeason) {
      setSelectedPlayerId(newPlayerId);
      setSearchParams({ playerId: newPlayerId, season: newSeason });
    }
  };

  const handleSeasonChange = (season: string) => {
    setSelectedSeason(season);
    if (selectedPlayerId) {
      setSearchParams({ playerId: selectedPlayerId, season });
    }
  };

  const handleSelectPlayer = (playerId: string, season: string) => {
    // Only update if the selection has changed
    if (playerId !== selectedPlayerId || season !== selectedSeason) {
      setSelectedPlayerId(playerId);
      setSearchParams({ playerId, season });
    }
  };

  useEffect(() => {
    const fetchPlayerProps = async () => {
      try {
        // Get first valid team ID
        const teams: TeamInfo[] = Object.values(TEAM_NAMES);
        if (teams.length === 0) {
          console.error('No teams found in TEAM_NAMES');
          return;
        }
        const playerId = teams[0].id;
        console.log('Fetching stats for player ID:', playerId);
        
        const [statsData, careerData] = await Promise.all([
          getPlayerStats(playerId.toString(), '2023-24'),
          getPlayerCareerInfo(playerId.toString())
        ]);

        if (!statsData || !careerData?.basicInfo) {
          throw new Error('Invalid player data received');
        }

        const formattedStats: FormattedPlayerStats = {
          ...statsData,
          PLAYER_ID: playerId,
          PLAYER_NAME: `${careerData.basicInfo.firstName} ${careerData.basicInfo.lastName}`,
          PROP_TYPE: 'Points',
          LINE: 25.5,
          ODDS: '-110',
          TEAM_ABBREVIATION: 'ATL'
        };

        console.log('Formatted player stats:', formattedStats);
        setPlayerStats([formattedStats]);
      } catch (error) {
        console.error('Error fetching player props:', error);
      }
    };

    fetchPlayerProps();
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col items-center gap-4 mb-8">
        <img 
          src="https://overdogbets.com/logo.png" 
          alt="Logo" 
          className="w-32"
        />
        <div className="text-center">
          <h1 className="text-3xl font-light tracking-wider bg-gradient-to-r from-white to-[#2cf583] 
                       bg-clip-text text-transparent">
            NBA Player Statistics
          </h1>
          <p className="text-gray-400">Search and analyze player performance</p>
        </div>
      </div>

      <div className="bg-[#13131A] rounded-xl p-6 shadow-xl">
        <div className="mb-6 flex items-center justify-between">
          <h2 className="text-xl font-semibold text-white">PlayerProps</h2>
          <div className="flex items-center space-x-4">
            <PlayerSearch 
              onSelectPlayer={handleSelectPlayer}
            />
            <button className="px-4 py-2 rounded-lg bg-[#00F6FF] text-[#0A0A0F] hover:bg-[#00DEE6] transition-colors">
              Filters
            </button>
          </div>
        </div>

        {selectedPlayerId ? (
          <div className="mt-6">
            <PlayerView playerId={selectedPlayerId} />
          </div>
        ) : (
          <>
            {loading ? (
              <div className="text-center py-8 text-[#8F9BB3]">Loading player props...</div>
            ) : (
              <div className="grid gap-4">
                {playerStats?.map((player) => (
                  <div key={player.PLAYER_ID} className="bg-[#1A1A24] rounded-lg p-4 flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <div className="w-8 h-8 bg-gray-700 rounded-full" />
                      <div>
                        <h3 className="text-white font-medium">{player.PLAYER_NAME}</h3>
                        <p className="text-[#8F9BB3] text-sm">{player.PROP_TYPE}</p>
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="text-white font-medium">Over {player.LINE}</div>
                      <div className="text-[#00F6FF] text-sm">{player.ODDS}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PropExplorerNBAPage;
