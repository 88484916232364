import React, { useState } from 'react';
import CoursePopup from './CoursePopup';

interface Lesson {
  title: string;
  content: string;
}

interface CourseCardProps {
  title: string;
  description: string;
  imageUrl: string;
  courseType: 'basic' | 'advanced';
  lessons?: Lesson[];
}

const CourseCard: React.FC<CourseCardProps> = ({ title, description, imageUrl, courseType, lessons }) => {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div className="bg-slate-800 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-48 object-cover"
      />
      <div className="p-6">
        <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
        <p className="text-slate-300">{description}</p>
        <button
          className="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition-colors"
          onClick={() => setShowPopup(true)}
        >
          Start Course
        </button>
        
        {showPopup && (
          <CoursePopup
            title={title}
            onClose={() => setShowPopup(false)}
            courseType={courseType}
            lessons={lessons}
          />
        )}
      </div>
    </div>
  );
};

export default CourseCard;