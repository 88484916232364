import React from 'react';
import { Facebook, Instagram, Twitter, Youtube, Linkedin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="landing-glass border-t border-white/10 py-12 md:py-20 overflow-hidden">
      <div className="landing-container relative">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#00F7F7]/3 to-transparent blur-2xl -z-10" />
        {/* Logo and Social Links */}
        <div className="flex flex-col items-center mb-12">
          {/* Logo */}
          <div className="flex items-center justify-center mb-8 md:mb-12">
            <img 
              src="/logo.png" 
              alt="Overdog Logo" 
              width="160" 
              height="40" 
              className="py-1 landing-transition transform hover:scale-105" 
            />
          </div>

          {/* Social Media Links */}
          <div className="flex flex-wrap justify-center gap-6 md:gap-10 w-full">
            <a href="#" className="text-white/60 landing-transition hover:text-white transform hover:scale-110 hover:rotate-6">
              <Facebook className="w-5 h-5" />
            </a>
            <a href="#" className="text-white/60 landing-transition hover:text-white transform hover:scale-110">
              <Instagram className="w-5 h-5" />
            </a>
            <a href="#" className="text-white/60 landing-transition hover:text-white transform hover:scale-110">
              <Twitter className="w-5 h-5" />
            </a>
            <a href="#" className="text-white/60 landing-transition hover:text-white transform hover:scale-110">
              <Youtube className="w-5 h-5" />
            </a>
            <a href="#" className="text-white/60 landing-transition hover:text-white transform hover:scale-110">
              <Linkedin className="w-5 h-5" />
            </a>
          </div>
        </div>

        {/* Navigation Links */}
        <div className="flex justify-center mb-10 md:mb-16 w-full">
          <div className="flex flex-wrap justify-center gap-6 md:gap-10 w-full">
            <a href="#" className="text-white/60 landing-transition hover:text-white text-base transform hover:translate-y-[-2px]">
              Pricing
            </a>
            <a href="#" className="text-white/60 landing-transition hover:text-white text-base transform hover:translate-y-[-2px]">
              Roadmap
            </a>
            <a href="#" className="text-white/60 landing-transition hover:text-white text-base">
              Submit feature/bug
            </a>
            <a href="#" className="text-white/60 landing-transition hover:text-white text-base">
              Affiliates
            </a>
            <a href="#" className="text-white/60 landing-transition hover:text-white text-base">
              Blog
            </a>
          </div>
        </div>

        {/* Copyright and Legal */}
        <div className="flex flex-col md:flex-row justify-between items-center text-xs md:text-sm text-white/40 w-full">
          <div className="mb-4 md:mb-0 text-center w-full md:text-left">© 2024 Overdog Bets LLC. All Rights Reserved</div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-6 items-center w-full md:w-auto">
            <a href="#" className="landing-transition hover:text-white transform hover:translate-y-[-2px]">
              Terms & conditions
            </a>
            <a href="#" className="landing-transition hover:text-white">
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
