import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ResizableBox } from 'react-resizable';
import { NBAPropsResponse, NBAPlayerPrediction } from '../../../types/nbaPredictions';
import { fetchNBAProps } from '../../../services/nbaPredictionsService';
import { useAuth } from '../../../hooks/useAuth';
import { useInjuries } from '../../../contexts/InjuryContext';
import { supabase } from '../../../lib/supabase/client';

type ViewMode = 'card' | 'list';
type Position = 'ALL' | 'G' | 'F' | 'C';
type SortField = 'PTS' | 'AST' | 'REB' | 'BLK' | 'STL';
type SortDirection = 'asc' | 'desc';

const SCROLL_THRESHOLD = 100;

const NBAProps = () => {
  const [props, setProps] = useState<NBAPropsResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<ViewMode>('list');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPosition, setSelectedPosition] = useState<Position>('ALL');
  const [sortField, setSortField] = useState<SortField>('PTS');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [height, setHeight] = useState(600);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const { injuries } = useInjuries();

  useEffect(() => {
    const loadProps = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.access_token) {
          const data = await fetchNBAProps(session.access_token);
          if (data) {
            const filteredData = filterOutInjuredPlayers(data);
            setProps(filteredData);
          }
        }
      } catch (err) {
        setError('Failed to load player props');
        console.error(err);
      }
    };

    if (user) loadProps();
  }, [user, injuries]);

  const filterOutInjuredPlayers = (data: NBAPropsResponse): NBAPropsResponse => {
    if (!data) return {};
    const injuredNames = injuries
      .filter(injury => injury.status === 'Out')
      .map(injury => injury.player.toLowerCase().replace(/[^a-z]/g, ''));
    
    return Object.fromEntries(
      Object.entries(data).filter(([_, player]) => 
        !injuredNames.includes(player.PLAYER.toLowerCase().replace(/[^a-z]/g, ''))
      )
    );
  };

  const handleSort = (field: SortField) => {
    setSortDirection(currentDirection => 
      sortField === field
        ? currentDirection === 'asc' ? 'desc' : 'asc'
        : 'desc'
    );
    setSortField(field);
  };

  const getSortIcon = (field: SortField) => 
    sortField !== field ? '↕️' : sortDirection === 'asc' ? '↑' : '↓';

  const handleScroll = () => {
    if (contentRef.current) {
      setShowBackToTop(contentRef.current.scrollTop > SCROLL_THRESHOLD);
    }
  };

  const filteredPlayers = Object.values(props || {})
    .filter(player => {
      if (!player?.predictions) return false;
      const query = searchQuery.toLowerCase();
      const matchesSearch = player.PLAYER.toLowerCase().includes(query) || 
                          player.TEAM?.toLowerCase().includes(query);
      const matchesPosition = selectedPosition === 'ALL' || 
                            player.POSITION.includes(selectedPosition);
      return matchesSearch && matchesPosition;
    })
    .sort((a, b) => {
      const aVal = a.predictions[sortField].prediction || 0;
      const bVal = b.predictions[sortField].prediction || 0;
      return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
    });

  const renderPredictionCell = (value: number, confidenceRating: number) => (
    <div className="text-center">
      <span className="font-medium">{value.toFixed(1)}</span>
      <div className="text-xs text-[#8F9BB3]">{confidenceRating.toFixed(1)}%</div>
    </div>
  );

  const renderPlayerCard = (player: NBAPlayerPrediction) => (
    <div className="bg-[#13131A] rounded-xl p-4 flex flex-col h-full">
      <div className="flex items-center gap-4 mb-4">
        <img 
          src={`https://cdn.nba.com/headshots/nba/latest/1040x760/${player.PLAYER_ID}.png`}
          alt={player.PLAYER}
          className="w-20 h-20 rounded-full"
        />
        <div>
          <Link 
            to={`/dashboard/nba/player?player=${encodeURIComponent(player.PLAYER)}`}
            className="text-white hover:text-[#00F6FF] transition-colors"
          >
            <h4 className="font-semibold">{player.PLAYER}</h4>
          </Link>
          <p className="text-[#8F9BB3] text-sm">{player.POSITION}</p>
          {player.TEAM && <p className="text-[#8F9BB3] text-sm">{player.TEAM}</p>}
        </div>
      </div>
      <div className="grid grid-cols-5 gap-2 text-white text-sm">
        {(['PTS', 'AST', 'REB', 'BLK', 'STL'] as SortField[]).map(stat => (
          <div 
            key={stat}
            onClick={() => handleSort(stat)}
            className="cursor-pointer hover:bg-[#1a1a2e] rounded p-2"
          >
            <div className="text-[#8F9BB3] mb-1">{stat} {getSortIcon(stat)}</div>
            {renderPredictionCell(
              player.predictions[stat].prediction,
              player.predictions[stat].confidence_rating
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const renderListView = (player: NBAPlayerPrediction) => (
    <div className="bg-[#13131A] rounded-xl p-4 flex items-center gap-4">
      <img 
        src={`https://cdn.nba.com/headshots/nba/latest/1040x760/${player.PLAYER_ID}.png`}
        alt={player.PLAYER}
        className="w-12 h-12 rounded-full"
      />
      <div className="flex-1 min-w-0">
        <Link 
          to={`/dashboard/nba/player?player=${encodeURIComponent(player.PLAYER)}`}
          className="text-white hover:text-[#00F6FF] transition-colors"
        >
          <h4 className="font-semibold truncate">{player.PLAYER}</h4>
        </Link>
        <p className="text-[#8F9BB3] text-sm truncate">
          {player.POSITION} {player.TEAM && `• ${player.TEAM}`}
        </p>
      </div>
      <div className="flex gap-4 text-white text-sm">
        {(['PTS', 'AST', 'REB', 'BLK', 'STL'] as SortField[]).map(stat => (
          <div 
            key={stat}
            onClick={() => handleSort(stat)}
            className="cursor-pointer hover:bg-[#1a1a2e] rounded p-2 min-w-[60px]"
          >
            <div className="text-[#8F9BB3] mb-1">{stat} {getSortIcon(stat)}</div>
            {renderPredictionCell(
              player.predictions[stat].prediction,
              player.predictions[stat].confidence_rating
            )}
          </div>
        ))}
      </div>
    </div>
  );

  if (error) return <div className="text-red-500 p-4">{error}</div>;
  if (!props) return <div className="text-white p-4">Loading player props...</div>;

  return (
    <div className="space-y-4">
      <div className="sticky top-0 z-10 bg-[#13131A] pb-4">
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <input
            type="text"
            placeholder="Search players or teams..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="flex-1 w-full px-4 py-2 bg-[#13131A] border border-[rgba(255,255,255,0.1)] rounded-lg text-white placeholder-[#8F9BB3] focus:outline-none focus:border-[#00F6FF]"
          />
          <div className="flex gap-2">
            <select
              value={selectedPosition}
              onChange={(e) => setSelectedPosition(e.target.value as Position)}
              className="px-4 py-2 bg-[#13131A] border border-[rgba(255,255,255,0.1)] rounded-lg text-white focus:outline-none focus:border-[#00F6FF]"
            >
              <option value="ALL">All Positions</option>
              <option value="G">Guards</option>
              <option value="F">Forwards</option>
              <option value="C">Centers</option>
            </select>
            <div className="flex rounded-lg overflow-hidden border border-[rgba(255,255,255,0.1)]">
              {(['card', 'list'] as ViewMode[]).map(mode => (
                <button
                  key={mode}
                  onClick={() => setViewMode(mode)}
                  className={`px-4 py-2 ${
                    viewMode === mode 
                      ? 'bg-[#4263EB] text-white' 
                      : 'text-[#8F9BB3] hover:text-white'
                  }`}
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-5 w-5"
                    viewBox="0 0 20 20" 
                    fill="currentColor"
                  >
                    {mode === 'card' ? (
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                    ) : (
                      <path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                    )}
                  </svg>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ResizableBox
        width={Infinity}
        height={height}
        minConstraints={[Infinity, 200]}
        onResize={(e, { size }) => setHeight(size.height)}
        handle={
          <div className="absolute -bottom-3 left-0 right-0 h-6 cursor-ns-resize flex justify-center items-center">
            <div className="w-10 h-0.5 bg-white/20 rounded hover:bg-white/40 transition-colors" />
          </div>
        }
        className="relative w-full bg-white/3 rounded-2xl border border-white/5"
        axis="y"
      >
        <div 
          ref={contentRef}
          className="h-full overflow-auto scrollbar-thin scrollbar-thumb-[#2E3449] scrollbar-track-transparent"
          onScroll={handleScroll}
        >
          <div className="p-4">
            <div className={
              viewMode === 'card' 
                ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'
                : 'space-y-4'
            }>
              {filteredPlayers.map((player, index) => (
                <div key={index} className="min-w-0">
                  {viewMode === 'card' 
                    ? renderPlayerCard(player) 
                    : renderListView(player)
                  }
                </div>
              ))}
            </div>
          </div>
        </div>

        {showBackToTop && (
          <button
            onClick={() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' })}
            className="absolute bottom-4 right-4 bg-[#4263EB] text-white p-2 rounded-full shadow-lg hover:bg-[#3651C9] transition-colors"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5" 
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path 
                fillRule="evenodd" 
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" 
                clipRule="evenodd" 
              />
            </svg>
          </button>
        )}
      </ResizableBox>
    </div>
  );
};

export default NBAProps;