import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

const PredictionCards = () => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    fetch('/header.json')
      .then(response => response.json())
      .then(data => setAnimationData(data))
      .catch(error => console.error('Error loading animation:', error));
  }, []);

  return (
    <div className="md:w-6/12 flex justify-center relative">
      {/* Subtle glow behind the animation */}
      <div className="absolute inset-0 bg-[#5983f0] opacity-[0.01] blur-2xl rounded-full landing-glow" />
      <div className="w-full max-w-xl relative landing-glow">
        {animationData && (
          <div className="landing-glass rounded-3xl overflow-hidden">
            <Lottie 
              animationData={animationData}
              loop={true}
              autoplay={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PredictionCards;
