import React, { useState, useEffect } from 'react';
import { Combobox } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useDebounce } from 'use-debounce';
import { getPlayerSuggestions, PlayerSuggestion } from '../../../../services/api';

interface PlayerSearchProps {
  onSelectPlayer: (playerId: string, season: string) => void;
}

const PlayerSearch: React.FC<PlayerSearchProps> = ({ onSelectPlayer }) => {
  const [query, setQuery] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerSuggestion | null>(null);
  const [season, setSeason] = useState(new Date().getFullYear().toString());
  const [debouncedQuery] = useDebounce(query, 300);
  const [players, setPlayers] = useState<PlayerSuggestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlayers = async () => {
      if (debouncedQuery.length < 2) {
        setPlayers([]);
        return;
      }

      setIsLoading(true);
      setError(null);
      
      try {
        const suggestions = await getPlayerSuggestions(debouncedQuery);
        setPlayers(suggestions || []);
      } catch (err) {
        setError('Failed to fetch players');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlayers();
  }, [debouncedQuery]);

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (selectedPlayer) {
      onSelectPlayer(selectedPlayer.id.toString(), season);
      setQuery('');
      setSelectedPlayer(null);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <Combobox<PlayerSuggestion | null> 
        value={selectedPlayer}
        onChange={(player: PlayerSuggestion | null) => {
          setSelectedPlayer(player);
          if (player) {
            setQuery(player.full_name);
          }
        }}
      >
        <div className="relative flex-1">
          <Combobox.Input
            className="w-full bg-gray-700 rounded-md py-2 pl-10 pr-4 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-emerald-500"
            placeholder="Search player..."
            displayValue={(player: PlayerSuggestion | null) => player?.full_name || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
          />
          <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {isLoading && (
              <div className="px-4 py-2 text-gray-400">Loading...</div>
            )}
            {!isLoading && players.length === 0 && debouncedQuery && (
              <div className="px-4 py-2 text-gray-400">No players found</div>
            )}
            {(players || []).map((player: PlayerSuggestion) => (
              <Combobox.Option
                key={player.id}
                value={player}
                className={({ active }: { active: boolean }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-emerald-600 text-white' : 'text-gray-300'
                  }`
                }
                onClick={(e: React.MouseEvent) => e.preventDefault()}
              >
                {player.full_name}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      </Combobox>

      <select
        value={season}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSeason(e.target.value)}
        className="bg-gray-700 rounded-md py-2 px-3 text-sm text-white focus:outline-none focus:ring-2 focus:ring-emerald-500"
      >
        {Array.from({ length: 5 }, (_, i) => {
          const year = new Date().getFullYear() - i;
          return (
            <option key={year} value={year}>
              {year}-{String(year + 1).slice(-2)}
            </option>
          );
        })}
      </select>

      <button
        onClick={handleSearch}
        disabled={!selectedPlayer}
        className="bg-emerald-600 hover:bg-emerald-700 text-white font-medium py-2 px-4 rounded-md text-sm transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Search
      </button>
    </div>
  );
};

export default PlayerSearch;
