import React from 'react';
import { Medal } from 'lucide-react';
import { TEAM_NAMES } from '../../../../../constants/teams';

interface GameLogProps {
  games: any[];
  highlightedStat: string;
  lineValue: number;
  overUnder: 'over' | 'under';
}

interface Column {
  key: string;
  label: string;
  className: string;
  format?: (value: any, game: any) => React.ReactNode;
}

interface GameData {
  Game_ID: string;
  GAME_DATE: string;
  MATCHUP: string;
  MIN: string;
  PTS: number;
  AST: number;
  REB: number;
  STL: number;
  BLK: number;
  TOV: number;
  FG3M: number;
  PF: number;
  [key: string]: any;
}

const GameLog: React.FC<GameLogProps> = ({ games, highlightedStat, lineValue, overUnder }) => {
  const getTeamName = (matchup: string): string => {
    const teams = matchup.split(' ');
    const teamCodes = teams.filter((t: string) => t !== 'vs.' && t !== '@');
    return teamCodes.map((code: string) => TEAM_NAMES[code]?.name || code).join(' vs ');
  };

  const checkSpecialPerformance = (game: GameData) => {
    const categories = ['PTS', 'REB', 'AST', 'STL', 'BLK'];
    const doubleDigits = categories.filter((cat: string) => (game[cat] || 0) >= 10);
    
    return {
      isDD: doubleDigits.length >= 2,
      isTD: doubleDigits.length >= 3,
      categories: doubleDigits
    };
  };

  const shouldHighlightRow = (game: GameData): boolean => {
    if (!highlightedStat || !lineValue) return false;

    let gameValue: number;
    const stats = highlightedStat.split('_');

    if (highlightedStat === 'DD') {
      gameValue = checkSpecialPerformance(game).isDD ? 1 : 0;
    } else if (highlightedStat === 'TD') {
      gameValue = checkSpecialPerformance(game).isTD ? 1 : 0;
    } else {
      gameValue = stats.reduce((sum: number, stat: string) => sum + (game[stat] || 0), 0);
    }

    return overUnder === 'over' 
      ? gameValue > Number(lineValue)
      : gameValue < Number(lineValue);
  };

  const columns = [
    { key: 'GAME_DATE', label: 'Date', className: 'text-left' },
    { key: 'MATCHUP', label: 'Matchup', className: 'text-left', format: getTeamName },
    { key: 'MIN', label: 'MIN', className: 'text-center' },
    { 
      key: 'PTS', 
      label: 'PTS', 
      className: `text-center ${highlightedStat === 'PTS' ? 'bg-emerald-500/10' : ''}`
    },
    { 
      key: 'AST', 
      label: 'AST', 
      className: `text-center ${highlightedStat === 'AST' ? 'bg-emerald-500/10' : ''}`
    },
    { 
      key: 'REB', 
      label: 'REB', 
      className: `text-center ${highlightedStat === 'REB' ? 'bg-emerald-500/10' : ''}`
    },
    { 
      key: 'STL', 
      label: 'STL', 
      className: `text-center ${highlightedStat === 'STL' ? 'bg-emerald-500/10' : ''}`
    },
    { 
      key: 'BLK', 
      label: 'BLK', 
      className: `text-center ${highlightedStat === 'BLK' ? 'bg-emerald-500/10' : ''}`
    },
    { 
      key: 'TOV', 
      label: 'TOV', 
      className: `text-center ${highlightedStat === 'TOV' ? 'bg-emerald-500/10' : ''}`
    },
    { 
      key: 'FG3M', 
      label: '3PM', 
      className: `text-center ${highlightedStat === 'FG3M' ? 'bg-emerald-500/10' : ''}`
    },
    { 
      key: 'PF', 
      label: 'PF', 
      className: `text-center ${highlightedStat === 'PF' ? 'bg-emerald-500/10' : ''}`
    },
    { 
      key: 'special', 
      label: 'Special', 
      className: `text-center w-24 ${['DD', 'TD'].includes(highlightedStat) ? 'bg-emerald-500/10' : ''}`,
      format: (_: any, game: GameData) => {
        const performance = checkSpecialPerformance(game);
        if (performance.isTD) {
          return (
            <div className="flex items-center justify-center gap-1">
              <Medal className="w-5 h-5 text-amber-400" />
              <span className="text-amber-400 font-medium">TD</span>
            </div>
          );
        } else if (performance.isDD) {
          return (
            <div className="flex items-center justify-center gap-1">
              <Medal className="w-5 h-5 text-blue-400" />
              <span className="text-blue-400 font-medium">DD</span>
            </div>
          );
        }
        return null;
      }
    }
  ];

  return (
    <div className="overflow-x-auto rounded-lg border border-gray-700">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-800/50">
            {columns.map((col) => (
              <th key={col.key} 
                  className={`p-3 text-sm font-medium text-gray-400 ${col.className}`}>
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {games.map((game) => {
            const isHighlighted = shouldHighlightRow(game);

            return (
              <tr
                key={game.Game_ID} 
                className={`
                  border-t border-gray-700 transition-colors
                  ${isHighlighted ? 'bg-emerald-500/10' : 'hover:bg-gray-800/30'}
                `}
              >
                {columns.map(col => (
                  <td key={col.key} 
                      className={`p-3 ${col.className}`}>
                    {col.format ? col.format(game[col.key], game) : game[col.key]}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GameLog;
