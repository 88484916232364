import { useState, useEffect, useCallback } from 'react';
import { getPlayerStats, getPlayerCareerInfo } from '../services/api';
import { PlayerStats, CareerInfoResponse } from '../types/nbaPlayerStats';

interface UsePlayerStatsState {
  stats: PlayerStats | null;
  loading: boolean;
  error: string | null;
  searchParams: {
    playerId: string | null;
    season: string;
  };
}

const usePlayerStats = (initialState = { playerId: null, season: '2024-25' }) => {
  const [state, setState] = useState<UsePlayerStatsState>({
    stats: null,
    loading: false,
    error: null,
    searchParams: initialState
  });

  const refreshStats = useCallback(async () => {
    const { playerId, season } = state.searchParams;
    if (!playerId) {
      setState(prev => ({...prev, stats: null, error: null}));
      return;
    }

    setState(prev => ({...prev, loading: true, error: null}));

    try {
      const [statsData, careerData] = await Promise.all([
        getPlayerStats(playerId, season),
        getPlayerCareerInfo(playerId)
      ]);

      if (!statsData || !Array.isArray(statsData.stats) || !Array.isArray(statsData.seasons)) {
        throw new Error('Invalid player stats data received');
      }

      setState(prev => ({
        ...prev,
        stats: {
          ...statsData,
          PLAYER_ID: Number(state.searchParams.playerId),
          PLAYER_NAME: `${careerData?.basicInfo?.first_name || ''} ${careerData?.basicInfo?.last_name || ''}`.trim(),
          PROP_TYPE: 'Points',
          LINE: 25.5,
          ODDS: '-110',
          careerInfo: careerData ? {
            basicInfo: {
              first_name: careerData.basicInfo?.first_name || '',
              last_name: careerData.basicInfo?.last_name || '',
              draft_year: careerData.basicInfo?.draft_year || '',
              draft_round: careerData.basicInfo?.draft_round || '',
              draft_number: careerData.basicInfo?.draft_number || '',
              first_nba_game: careerData.basicInfo?.first_nba_game || '',
              college: careerData.basicInfo?.college || ''
            },
            seasons: careerData.seasons || []
          } : undefined
        },
        loading: false
      }));
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: err instanceof Error ? err.message : 'Failed to fetch player stats',
        loading: false
      }));
    }
  }, [state.searchParams]);

  useEffect(() => {
    refreshStats();
  }, [refreshStats]);

  return {
    stats: state.stats,
    loading: state.loading,
    error: state.error,
    setSearchParams: (params: Partial<UsePlayerStatsState['searchParams']>) => 
      setState(prev => ({
        ...prev,
        searchParams: {...prev.searchParams, ...params}
      })),
    refreshStats
  };
};

export default usePlayerStats;
