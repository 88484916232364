import React from 'react';

export const basicLessons = [
  {
    title: "Introduction to Sports Betting",
    content: `Sports betting is the activity of predicting sports results and placing a wager on the outcome. This course will teach you the fundamentals of sports betting, including:

- Understanding odds and probabilities
- Different types of bets
- Bankroll management basics
- Reading and analyzing sports statistics
- Common betting terminology
- Responsible gambling practices

The course consists of 8 lessons designed to give you a solid foundation in sports betting.`
  },
  {
    title: "Lesson 1: Understanding Odds",
    content: `Odds represent the probability of an event occurring and determine how much you can win from a bet.

1. Decimal Odds: Most common format (e.g., 2.50)
   - Potential Return = Stake × Odds
   - Example: $10 at 2.50 = $25 return

2. Fractional Odds: Traditional format (e.g., 3/1)
   - Potential Profit = Stake × (Numerator/Denominator)
   - Example: $10 at 3/1 = $30 return ($20 profit + $10 stake)

3. American Odds: Positive and negative numbers
   - Positive odds (e.g., +200): $100 bet wins $200
   - Negative odds (e.g., -150): Need to bet $150 to win $100`
  },
  {
    title: "Lesson 2: Types of Bets",
    content: `There are several common types of bets in sports betting:

1. Moneyline: Betting on which team will win
2. Point Spread: Betting on a team to win by a certain margin
3. Totals (Over/Under): Betting on the total points scored
4. Parlays: Combining multiple bets for higher payouts
5. Futures: Long-term bets on future events
6. Props: Bets on specific events within a game

Each bet type has its own strategies and risk levels. Start with simple bets like moneyline before exploring more complex options.`
  },
  {
    title: "Lesson 3: Bankroll Management",
    content: `Proper bankroll management is crucial for long-term success:

1. Set a Budget: Determine how much you can afford to lose
2. Unit System: Bet a consistent percentage of your bankroll (1-5%)
3. Track Your Bets: Keep records of all wagers
4. Avoid Chasing Losses: Stick to your strategy
5. Set Win/Loss Limits: Know when to stop

Example: With a $1000 bankroll, bet $20-$50 per wager (2-5% of bankroll).`
  },
  {
    title: "Lesson 4: Reading Sports Statistics",
    content: `Understanding sports statistics helps make informed bets:

Key Statistics to Analyze:
1. Team Performance: Win/loss records, home/away splits
2. Player Stats: Individual performance metrics
3. Head-to-Head: Historical matchups between teams
4. Recent Form: Last 5-10 games performance
5. Injuries/Suspensions: Impact of missing players

Use reliable sources for statistics and consider multiple factors when analyzing.`
  },
  {
    title: "Lesson 5: Common Betting Terms",
    content: `Essential betting terminology:

1. Action: A bet that's been accepted
2. Handle: Total amount wagered on an event
3. Juice/Vig: Bookmaker's commission
4. Push: When a bet results in a tie
5. Sharp: Professional, experienced bettor
6. Square: Recreational, casual bettor
7. Line Movement: Changes in odds or point spreads

Understanding these terms will help you navigate betting platforms and discussions.`
  },
  {
    title: "Lesson 6: Responsible Gambling",
    content: `Important principles for safe betting:

1. Set Limits: Time and money limits
2. Never Chase Losses: Stick to your budget
3. Avoid Emotional Betting: Make rational decisions
4. Take Breaks: Step away when needed
5. Seek Help if Needed: Use problem gambling resources

Remember: Sports betting should be entertaining, not a source of financial stress.`
  },
  {
    title: "Lesson 7: Getting Started",
    content: `Steps to begin your betting journey:

1. Choose a Reputable Bookmaker: Licensed and regulated
2. Understand the Rules: Read terms and conditions
3. Start Small: Begin with small wagers
4. Focus on One Sport: Master one before expanding
5. Learn Continuously: Stay updated on strategies and trends

Take your time to learn and develop your skills before increasing your stakes.`
  }
];