import React from 'react';

interface TestimonialCardProps {
  avatar: string;
  name: string;
  role: string;
  quote: string;
}

const TestimonialCard = ({ avatar, name, role, quote }: TestimonialCardProps) => (
  <div className="bg-black/40 backdrop-blur-sm rounded-3xl border border-white/10 p-8 h-full flex flex-col shadow-xl hover:shadow-2xl transition-all duration-300">
    <div className="flex items-center space-x-3 mb-4">
      <img 
        src={avatar || "/api/placeholder/48/48"} 
        alt={name} 
        className="w-12 h-12 rounded-full ring-2 ring-white/10 shadow-lg"
      />
      <div>
        <div className="text-white/90 font-medium">{name}</div>
        <div className="text-white/40 text-sm">{role}</div>
      </div>
    </div>
    <div className="text-white/80 text-lg leading-relaxed flex-grow">
      "{quote}"
    </div>
  </div>
);

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: "KaashK9",
      role: "Discord Member",
      quote: "Great results 9/10. Insane accuracy with a $ back guarantee almost immediately",
      avatar: "/api/placeholder/48/48"
    },
    {
      name: "Tyler Fuentes",
      role: "Overdog User",
      quote: "I have been sports betting for a few years now, and I've really tried to make it a second income in my life, but it hasn't been easy. I've been using Overdog for two months now, and it has been a game changer for me. I was profitable already in my first week and have been building up since then. I highly recommend it to anyone who wants to make betting a second income and earn a lot of money from it. It's basically free money.",
      avatar: "/api/placeholder/48/48"
    },
    {
      name: "Jayce Reinhart",
      role: "Overdog User",
      quote: "Overdog AI has amazing player prop predictions, always super accurate, and helps build accurate 3-5 leg parlays! AI Game Winner predictions are nice to build a bankroll or do ladders with, giving winner and Over/Under Predictions, great 2 leg parlays! Overdog really is rewriting the rules like they say.",
      avatar: "/api/placeholder/48/48"
    }
  ];

  return (
    <div className="py-20">
      <div className="container mx-auto px-6">
        {/* Gradient Heading */}
        <h2 className="text-5xl md:text-6xl font-light mb-16 leading-tight mx-auto text-center max-w-4xl">
          <span style={{
            background: 'linear-gradient(90deg, #F7D618 0%, #00F7F7 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: '0 0 20px rgba(0,247,247,0.2)'
          }}>
            People just like you
          </span>
          <span className="text-white block">
            are already using OVERDOG
            <br />
            to stay in profit, make smarter bets and
            <br />
            take back the edge!
          </span>
        </h2>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              name={testimonial.name}
              role={testimonial.role}
              quote={testimonial.quote}
              avatar={testimonial.avatar}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
