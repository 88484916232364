import { NBAPredictionsResponse, NBAPrediction } from '../types/nbaPredictions';
import { supabase } from '../lib/supabase/client';

const NBA_SPORT_ID = '3bd80add-015f-4605-8465-9603f79ab951';
const NBA_LEAGUE_ID = '615abc50-d0ad-4c6e-b396-43a546419e24';

interface ExternalGameData {
  game_id: string;
  teams: {
    home: {
      name: string;
      lineups: {
        starters: any[];
        bench: any[];
        rotations: any[];
      };
    };
    away: {
      name: string;
      lineups: {
        starters: any[];
        bench: any[];
        rotations: any[];
      };
    };
  };
  team_health: {
    home: number;
    away: number;
  };
  recent_form: {
    home: string;
    away: string;
  };
}

const createDefaultGameData = (gameId: string): ExternalGameData => ({
  game_id: gameId,
  teams: {
    home: {
      name: '',
      lineups: {
        starters: [],
        bench: [],
        rotations: []
      }
    },
    away: {
      name: '',
      lineups: {
        starters: [],
        bench: [],
        rotations: []
      }
    }
  },
  team_health: { 
    home: 100, 
    away: 100 
  },
  recent_form: { 
    home: '-', 
    away: '-' 
  }
});

export const fetchNBAPredictions = async (authToken: string): Promise<NBAPredictionsResponse> => {
  try {
    const { data: predictions, error: predictionsError } = await supabase
      .from('predictions')
      .select(`
        *,
        events (
          id,
          home_team_name,
          away_team_name,
          start_time,
          sport_id,
          league_id
        )
      `)
      .eq('sport_id', NBA_SPORT_ID)
      .eq('league_id', NBA_LEAGUE_ID)
      .or('result.is.null,result.eq.""');

    if (predictionsError || !predictions) {
      throw new Error('Failed to fetch predictions from database');
    }

    const date = new Date().toISOString().split('T')[0];
    // Get signed URL with access token
    const { data: signedUrlData, error: signedUrlError } = await supabase.storage
      .from('predictions')
      .createSignedUrl(`${date}_predictions.json`, 60);
    
    if (signedUrlError || !signedUrlData?.signedUrl) {
      throw new Error(`Failed to get signed URL: ${signedUrlError?.message}`);
    }

    const response = await fetch(signedUrlData.signedUrl, {
      headers: { Authorization: `Bearer ${authToken}` }
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to fetch predictions: ${response.status} ${response.statusText}\n${errorBody}`);
    }
    
    const externalData = await response.json();

    const games = predictions
      .filter(prediction => prediction.events)
      .map(prediction => {
        const event = prediction.events;
        
        const externalId = event.id;
        const externalGame = externalData.games?.find((g: { game_id: string; }) => g.game_id === externalId);

        console.log('ID matching:', {
          databaseId: event.id,
          externalGameId: externalId,
          foundMatch: !!externalGame,
          availableExternalIds: externalData.games.map((g: { game_id: any; }) => g.game_id)
        });

        const game: NBAPrediction = {
          gameId: event.id.toString(),
          teams: {
            home: {
              name: event.home_team_name,
              lineups: externalGame?.teams.home.lineups || {
                starters: [],
                bench: [],
                rotations: []
              }
            },
            away: {
              name: event.away_team_name,
              lineups: externalGame?.teams.away.lineups || {
                starters: [],
                bench: [],
                rotations: []
              }
            }
          },
          prediction: {
            home_win_prob: prediction.home_win_probability ?? externalGame?.prediction?.home_win_prob ?? 50,
            away_win_prob: prediction.away_win_probability ?? externalGame?.prediction?.away_win_prob ?? 50,
            winner: ((prediction.home_win_probability ?? externalGame?.prediction?.home_win_prob ?? 50) > 
                    (prediction.away_win_probability ?? externalGame?.prediction?.away_win_prob ?? 50))
              ? event.home_team_name
              : ((prediction.away_win_probability ?? externalGame?.prediction?.away_win_prob ?? 50) > 
                 (prediction.home_win_probability ?? externalGame?.prediction?.home_win_prob ?? 50))
                ? event.away_team_name
                : 'AI Unsure',
            spread_confidence_interval: {
              low: prediction.spread_confidence_low || 0,
              high: prediction.spread_confidence_high || 0
            }
          },
          team_health: externalGame?.team_health || {
            home: 100,
            away: 100
          },
          recent_form: externalGame?.recent_form || {
            home: '-',
            away: '-'
          },
          eventid: event.id.toString()
        };

        // Validate probabilities sum to 100%
        const total = game.prediction.home_win_prob + game.prediction.away_win_prob;
        if (total !== 100) {
          console.warn(`Invalid probabilities for game ${game.gameId}: Home ${game.prediction.home_win_prob}% + Away ${game.prediction.away_win_prob}% = ${total}%`);
          // Normalize probabilities
          // More precise probability normalization
          const homeProb = (game.prediction.home_win_prob / total) * 100;
          const awayProb = 100 - homeProb;
          
          game.prediction.home_win_prob = Number(homeProb.toFixed(1));
          game.prediction.away_win_prob = Number(awayProb.toFixed(1));
          
          console.log(`Normalized probabilities for game ${game.gameId}:`, {
            originalHome: prediction.home_win_probability,
            originalAway: prediction.away_win_probability,
            normalizedHome: game.prediction.home_win_prob,
            normalizedAway: game.prediction.away_win_prob
          });
        }

        return game;
      });

    return {
      date: date,
      games: games
    };
  } catch (error) {
    console.error('Error in fetchNBAPredictions:', error);
    throw error;
  }
};

export const fetchNBAProps = async (authToken: string): Promise<any> => {
  const date = new Date().toISOString().split('T')[0];
  // Get signed URL with access token
  const { data: signedUrlData, error: signedUrlError } = await supabase.storage
  .from('player_props_nba')
  .createSignedUrl(`${date}.json`, 60);

  if (signedUrlError || !signedUrlData?.signedUrl) {
    throw new Error(`Failed to get signed URL: ${signedUrlError?.message}`);
  }

  const response = await fetch(signedUrlData.signedUrl, {
    headers: { Authorization: `Bearer ${authToken}` }
  });


  if (!response.ok) {
    console.error('Failed to fetch NBA props:', response.statusText);
    throw new Error('Failed to fetch NBA props');
  }

  return response.json();
};
