import React, { useState } from 'react';
import CourseContentPopup from './CourseContentPopup';
import { advancedLessons } from './AdvancedStrategiesContent';

interface Lesson {
  title: string;
  content: string;
}

interface CoursePopupProps {
  title: string;
  onClose: () => void;
  courseType: 'basic' | 'advanced';
  lessons?: Lesson[];
}

const CoursePopup: React.FC<CoursePopupProps> = ({ title, onClose, courseType, lessons }) => {
  const [showContentPopup, setShowContentPopup] = useState(false);
  
  const handleStartCourse = () => {
    setShowContentPopup(true);
  };
  
  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
      style={{ 
        backdropFilter: 'blur(4px)',
        zIndex: 9999
      }}
    >
      <div className="bg-slate-800 rounded-lg w-full max-w-2xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">{title}</h2>
          <button 
            onClick={onClose}
            className="text-slate-400 hover:text-white transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="space-y-4 text-slate-300">
          <p>
            In this course you will learn:
          </p>
          <ul className="list-disc list-inside space-y-2">
            {lessons?.map((lesson, index) => (
              <li key={index}>{lesson.title.replace('Lesson ' + (index + 1) + ': ', '')}</li>
            ))}
          </ul>
          <p className="pt-4">
            The course consists of {lessons?.length} lessons with {courseType === 'basic' ? 'fundamental' : 'advanced'} theory and practical applications.
          </p>
          <button
            className="mt-6 w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-6 rounded-lg transition-colors"
            onClick={handleStartCourse}
          >
            Start Course
          </button>
        </div>
      </div>
      
      {showContentPopup && (
        <CourseContentPopup
          courseType={courseType}
          lessons={lessons}
          onClose={() => {
            setShowContentPopup(false);
            onClose();
          }}
        />
      )}
    </div>
  );
};

export default CoursePopup;